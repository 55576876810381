@import url("../../../assets/styles/input-styles/_underline.css");

.im-checkbox {
    width: auto;
    vertical-align: text-bottom;
    border: 1px solid #ccc;
    margin-top: 0px !important;
    margin-right: 4px !important;
}

fieldset.im-fs {
    font-size: small;
    font-weight: normal;
    border: 1px solid #ccc; /*#999999 ;*/
    border-radius: 6px;
    padding-left: 8px;
}

legend.im-legend {
    padding-left: 5px;
    padding-right: 5px;
    color: #888888;
    background-color: transparent;
    font-weight: bold;
    margin-bottom: 10px;
}

.right-inner-addon {
    position: relative;
}

.right-inner-addon input {
    padding-right: 30px;
}

.right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
}

.input-group-addon[type="text"] {
    text-align: left;
}

.input-group-addon:not(:first-child):not(:last-child) {
    border-left: 0;
}

.input-group-icon {
    position: absolute;
    color: black;
    font-size: 20px;
    top: 14px;
    left: 14px;
    z-index: 2;
}

.input-text {
    text-align: left;
    font-size: 30px;
}

.tt-input__form-control,
.tt-input__icon {
    appearance: none;
    padding: 0.2rem 0.2rem 0 0.2rem;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--tt-input--color);
    background: var(--tt-input--background);
    /*background: transparent;*/
    /*    border-left: var(--tt-input__form-control--border-left);
    border-top: var(--tt-input__form-control--border-top);
    border-right: var(--tt-input__form-control--border-right);
    border-bottom: var(--tt-input__form-control--border-bottom);*/
    outline: 0.2rem solid transparent;
    outline-offset: -0.1rem;
    box-shadow: var(--tt-input__form-control--box-shadow);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    border-radius: 0;
    -webkit-border-radius: 0;
    border: none;
    font-size: 1.2rem;
    font-size: var(--tt-font-size);
    height: var(--tt-height);
    padding-top: var(--tt-padding-top);
    padding-bottom: var(--tt-padding-bottom);
    padding-right: var(--tt-padding-right);
    padding-left: var(--tt-padding-left);
}

.tt-input__form-control::-webkit-outer-spin-button,
.tt-input__form-control::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.tt-input__form-control[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.tt-input__form-control[readonly] {
    background: var(--tt-input--background--readonly);
    /*border-bottom: var(--tt-input__form-control--border-bottom--readonly);*/
}

.tt-input__form-control[disabled] {
    color: var(--tt-input--color--disabled, --tt-input--color);
    background: var(--tt-input--background--disabled);
    cursor: not-allowed;
    /*border-left: var(--tt-input__form-)*/
    border: none;
    opacity: 1;
}

.tt-input__form-control:focus,
.tt-input__form-control:focus-visible,
.tt-input__group--invalid .tt-input__form-control:focus,
tt-input__group--invalid .tt-input__form-control:focus-visible {
    outline: 0.2rem solid var(--tt-focus-color) !important;
    outline-offset: -0.1rem !important;
    box-shadow: none;
}

.tt-input__group--invalid .tt-input__form-control,
tt-input__group--invalid .tt-input__form-control {
    /*.tt-input__group--invalid .tt-input__form-control:focus, tt-input__group--invalid .tt-input__form-control:focus-visible {*/
    outline: 0.2rem solid var(--tt-danger-color) !important;
    outline-offset: -0.1rem !important;
    box-shadow: none;
}

.tt-input__form-control--select {
    padding-right: 2.6rem;
    background-image: var(--tt-input--chevron--url);
    background-position: right 0.6rem center;
    background-repeat: no-repeat no-repeat;
    background-size: auto 40%;
}

.tt-input__form-control--checkbox {
    aspect-ratio: 1 / 1;
    padding: 0;
    appearance: none;
    background: transparent;
    border: var(--tt-input__checkbox--border);
    border-radius: var(--tt-input__checkbox--border-radius);
    width: 1.2rem;
    height: 1.2rem;
    width: var(--tt-font-size);
    height: var(--tt-font-size);
    font-size: var(--tt-font-size);
}

.tt-input__form-control--checkbox:checked {
    background: var(--tt-input__checkbox--color);
}

.tt-input__form-control--checkbox:checked::before {
    content: "\f00c"; /*Font-awesome checkmark*/
    font-family: "FONT AWESOME 5 PRO";
    font-size: calc(100% - 4px);
    color: var(--tt-input__checkbox--color--checked);
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tt-input__group--invalid .tt-input__form-control--checkbox:focus,
.tt-input__group--invalid .tt-input__form-control--checkbox:focus-visible,
.tt-input__form-control--checkbox:focus,
.tt-input__form-control--checkbox:focus-visible {
    outline-offset: 0.1rem !important;
}

.tt-input__group--invalid .tt-input__form-control--checkbox,
tt-input__group--invalid .tt-input__form-control--checkbox {
    /*.tt-input__group--invalid .tt-input__form-control:focus, tt-input__group--invalid .tt-input__form-control:focus-visible {*/
    outline: 0.2rem solid var(--tt-danger-color) !important;
    outline-offset: 0.1rem !important;
}

.tt-input__form-control--textarea {
    min-height: 64px;
    display: block;
    white-space-collapse: preserve;
    line-height: 1.3333333;
    /*text-wrap: initial;*/
    white-space: break-spaces;
    min-height: calc(var(--tt-height) * 3);
    height: initial;
}

.tt-input__group {
    display: flex;
    border-left: var(--tt-input__form-control--border-left);
    border-right: var(--tt-input__form-control--border-right);
    border-bottom: var(--tt-input__form-control--border-bottom);
    border-top: var(--tt-input__form-control--border-top);
    background: var(--tt-input--background);
}

.tt-input__group--readonly {
    display: flex;
    border-left: var(--tt-input__form-control--border-left--readonly);
    border-right: var(--tt-input__form-control--border-right--readonly);
    border-bottom: var(--tt-input__form-control--border-bottom--readonly);
    border-top: var(--tt-input__form-control--border-top--readonly);
    background: var(--tt-input--background--readonly);
}

.tt-input__group--disabled > .tt-input__form-control {
    display: flex;
    border-left: var(--tt-input__form-control--border-left--readonly);
    border-right: var(--tt-input__form-control--border-right--readonly);
    border-bottom: var(--tt-input__form-control--border-bottom--readonly);
    border-top: var(--tt-input__form-control--border-top--readonly);
    background: var(--tt-input--background--readonly);
}

.tt-input__group--checkbox {
    border: none;
    background: transparent;
}

.tt-input__group--file-uploader {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
    background: transparent;
}

/*.tt-input__group > .tt-input__form-control:first-child,
.tt-input__group > .tt-input__icon:first-child,
.tt-input__group > .tt-input__button:first-child {
    -webkit-border-top-left-radius: var(--tt-input--border-top-left-radius);
    border-top-left-radius: var(--tt-input--border-top-left-radius);
    -webkit-border-bottom-left-radius: var(--tt-input--border-bottom-left-radius);
    border-bottom-left-radius: var(--tt-input--border-bottom-left-radius);
}

.tt-input__group > .tt-input__form-control:last-child,
.tt-input__group > .tt-input__button:last-child {
    -webkit-border-top-right-radius: var(--tt-input--border-top-right-radius);
    border-top-right-radius: var(--tt-input--border-top-right-radius);
    -webkit-border-bottom-right-radius: var(--tt-input--border-bottom-right-radius);
    border-top-right-radius: var(--tt-input--border-top-right-radius);
}
*/
.tt-input__group {
    -webkit-border-top-left-radius: var(--tt-input--border-top-left-radius);
    border-top-left-radius: var(--tt-input--border-top-left-radius);
    -webkit-border-bottom-left-radius: var(
        --tt-input--border-bottom-left-radius
    );
    border-bottom-left-radius: var(--tt-input--border-bottom-left-radius);
}

.tt-input__group {
    -webkit-border-top-right-radius: var(--tt-input--border-top-right-radius);
    border-top-right-radius: var(--tt-input--border-top-right-radius);
    -webkit-border-bottom-right-radius: var(
        --tt-input--border-bottom-right-radius
    );
    border-bottom-right-radius: var(--tt-input--border-top-right-radius);
}

:not(.tt-input__icon, .tt-input__button)
    + .tt-input__form-control:not(:first-child) {
    border-left: 0.1rem solid var(--tt-input--border-color);
}

.tt-input__form-control--multi:not(:first-child) {
    border-left: 0.1rem solid var(--tt-input--border-color);
}

.tt-input__container {
    container: tt-input / inline-size;
    margin-bottom: 7px;
}

.tt-input__base {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
}

.tt-input__label {
    width: 100%;
    grid-column: 1;
    color: var(--tt-input__label--color);
    margin: 0px;
    text-align: left;
    text-transform: var(--tt-input__label--text-transform);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--tt-input__label--font-weight);
    font-size: 1rem;
    font-size: var(--tt-font-size-sm);
}

.tt-input__label--required {
    margin: 0px;
    padding-left: 0px;
    text-align: left;
    color: crimson;
}

.tt-input__label--multi {
    grid-column: 1;
    text-transform: var(--tt-input__label--text-transform);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--tt-input__label--font-weight);
}

.tt-input__sublabel {
    margin: 0px;
    padding-left: 1px;
    text-align: left;
    color: var(--tt-input__sublabel--color);
    font-weight: 500;
    font-size: 1rem;
    font-size: var(--tt-font-size-xs);
    align-content: flex-end;
}

.tt-input__group {
    width: 100%;
    grid-column: 1;
}

.tt-input__group--buttons {
    display: flex;
}

.tt-input__group--date-range {
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    border-collapse: collapse;
}

.tt-input__group---fieldset {
    background: transparent;
    border: none;
    flex: 1;
}

.tt-input__group--fieldset-container {
    display: flex;
    gap: 0.6rem;
    width: 100%;
}

.tt-input__group--fieldset-container
    > div.tt-input__group.tt-input__group--fieldset-buttons {
    /* TODO: get variable for border width instead of using 1 px */
    width: fit-content;
    height: calc(100% + 1px);
}

.tt-input__base > .tt-input__group.tt-input__group--fieldset-container {
    background: transparent;
    border: none;
}

.tt-input__form-controls--date-range {
    display: flex;
    width: 100%;
    display: flex;
    border-left: var(--tt-input__form-control--border-left);
    border-right: var(--tt-input__form-control--border-right);
    border-bottom: var(--tt-input__form-control--border-bottom);
    border-top: var(--tt-input__form-control--border-top);
    background: var(--tt-input--background);
}

.tt-input__button {
    position: relative;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0px 11px;
    width: auto;
    border: none;
    /*background: var(--tt-input--background);*/
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.1s ease-in;
    border-radius: 0;
    font-size: var(--tt-font-size);
    height: var(--tt-height);
    /*border-bottom: var(--tt-input__form-control--border-bottom);*/
}

.tt-input__form-control[readonly] ~ .tt-input__button {
    background: var(--tt-input--background--readonly);
    /*border-bottom: var(--tt-input__form-control--border-bottom--readonly);*/
}

.tt-input__button.btn-primary,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-primary,
.tt-input__button--primary,
.tt-input__form-control[readonly] ~ .tt-input__button--primary {
    color: var(--tt-primary-color);
    background: transparent;
}

.tt-input__button.btn-primary:hover,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-primary:hover,
.tt-input__button.btn-primary:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-primary:focus-visible,
.tt-input__button--primary:hover,
.tt-input__form-control[readonly] ~ .tt-input__button--primary:hover,
.tt-input__button--primary:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button--primary:focus-visible {
    border-bottom: 0.1rem solid var(--tt-primary-color);
    background-color: var(--tt-primary-color);
    color: white;
    opacity: 1;
}

.tt-input__button.btn-danger,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-danger,
.tt-input__button.btn-danger:focus,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-danger:focus,
.tt-input__button--danger,
.tt-input__form-control[readonly] ~ .tt-input__button--danger,
.tt-input__button--danger:focus,
.tt-input__form-control[readonly] ~ .tt-input__button--danger:focus {
    color: var(--tt-danger-color);
}

.tt-input__button.btn-danger:hover,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-danger:hover,
.tt-input__button.btn-danger:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-danger:focus-visible,
.tt-input__button--danger:hover,
.tt-input__form-control[readonly] ~ .tt-input__button--danger:hover,
.tt-input__button--danger:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button--danger:focus-visible {
    border-bottom: 0.1rem solid var(--tt-danger-color);
    background-color: var(--tt-danger-color);
    color: white;
}

.tt-input__button.btn-success,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-success,
.tt-input__button.btn-success:focus,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-success:focus,
.tt-input__button.btn-success:active,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-success:active,
.tt-input__button--success:focus,
.tt-input__form-control[readonly] ~ .tt-input__button--success:focus,
.tt-input__button--success:active,
.tt-input__form-control[readonly] ~ .tt-input__button--success:active,
.tt-input__button--success,
.tt-input__form-control[readonly] ~ .tt-input__button--success {
    color: var(--tt-success-color);
    background: transparent;
}

.tt-input__button.btn-success:hover,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-success:hover,
.tt-input__button.btn-success:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button.btn-success:focus-visible,
.tt-input__button--success:hover,
.tt-input__form-control[readonly] ~ .tt-input__button--success:hover,
.tt-input__button--success:focus-visible,
.tt-input__form-control[readonly] ~ .tt-input__button--success:focus-visible {
    border-bottom: 0.1rem solid var(--tt-success-color);
    background-color: var(--tt-success-color);
    color: white;
}

.tt-input__icon {
    width: fit-content;
    padding: 0 11px;
    pointer-events: none;
}

.tt-input__icon--invalid {
    color: var(--tt-danger-color);
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.tt-input__base--label-side {
    display: grid;
    grid-template-columns: 16.66666667% 1fr;
    grid-template-rows: 1fr;
}

.tt-input__base--label-side > .tt-input__label {
    width: 100%;
    grid-column: 1;
}

.tt-input__base--label-side > .tt-input__group {
    width: 100%;
    grid-column: 2;
}

.tt-date-range__buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: none;
    border-collapse: collapse;
}

.tt-date-range__button:last-child {
    border-right: none;
}

.tt-date-range__button {
    flex: 1;
    border: 0.1rem solid var(--tt-input__date-range--color);
    color: var(--tt-input__date-range--text-color);
    border: none;
    /*border-left: 0.1rem solid var(--tt-secondary-color);*/
    border-right: 0.1rem solid var(--tt-input__date-range--color);
    opacity: 1;
}

.tt-date-range__button:hover {
    flex: 1;
    border: 0.1rem solid var(--tt-input__date-range--color);
    color: var(--tt-input__date-range--color);
    border: none;
    /*border-left: 0.1rem solid var(--tt-secondary-color);*/
    border-right: 0.1rem solid var(--tt-input__date-range--color);
    background-color: rgba(var(--tt-secondary-color-rgb), 0.1);
    background-color: var(--tt-input__date-range--color--hover);
    opacity: 1;
}

.tt-date-range__button:last-child:hover {
    border-right: none;
}

.tt-date-range__button--active,
.tt-date-range__button:active {
    flex: 1;
    background: var(--tt-input__date-range--color);
    color: var(--tt-input__date-range--text-color--active);
}

.tt-date-range__button--active:hover {
    flex: 1;
    background: var(--tt-input__date-range--color);
    color: var(--tt-input__date-range--text-color--active);
}

.tt-date-range__button + .tt-date-range__button {
    border-left: none;
}

.tt-date-range__button::after {
    content: none;
}

.tt-date-range__button::before {
    content: none;
}

.tt-date-range__button-container {
    width: 100%;
    display: flex;
    flex: 1;
    min-width: 20rem;
    margin-left: -0.1rem;
    border-collapse: collapse;
    border: 0.1rem solid var(--tt-input__date-range--color);
}

/* xxs */
@container tt-input (min-width: 384px) {
    .tt-input__base {
        grid-template-columns: 25% 1fr;
        grid-template-rows: 1fr;
    }

    .tt-input__label {
        width: 100%;
        grid-column: 1;
    }

    .tt-input__group {
        width: 100%;
        grid-column: 2;
    }
}
/* xs */
@container tt-input (min-width: 576px) {
    /* .tt-input__base {
        grid-template-columns: 16.66666667% 1fr;
        grid-template-rows: 1fr;
    }*/
    /*.tt-input__group {
        flex-grow: 3;
    }*/
}

/* sm */
@container tt-input (min-width: 768px) {
    .tt-input__base {
        grid-template-columns: 16.66666667% 1fr;
        grid-template-rows: 1fr;
    }

    .tt-input__label {
        width: 100%;
        grid-column: 1;
        grid-row: 1 / 3;
    }

    .tt-input__group {
        width: 100%;
        grid-column: 2;
    }
}

/* md */
@container tt-input (min-width: 992px) {
    /* .tt-input__base {
        grid-template-columns: 16.66666667% 1fr;
        grid-template-rows: 1fr;
    }*/

    .tt-input__group--date-range {
        display: grid;
        grid-template-rows: min-content auto;
        grid-template-columns: 40% 60%;
    }

    .tt-input__form-controls--date-range {
        grid-row: 2;
        grid-column: 1;
    }

    .tt-input__buttons--date-range {
        grid-row: 2;
    }
}

/* lg */
/*@container tt-input (min-width: 1200px) {*/
/*.tt-input__base {
        grid-template-columns: 16.66666667% 1fr;
        grid-template-rows: 1fr;
    }*/
/*}*/

/* xl */
/*@container tt-input (min-width: 1400px) {*/
/*.tt-input__base {
        grid-template-columns: 8.33333333% 1fr;
        grid-template-rows: 1fr;
    }*/
/*}*/

/* xxl */
@container tt-input (min-width: 1920px) {
    .tt-input__base {
        grid-template-columns: 8.33333333% 1fr;
        grid-template-rows: 1fr;
    }

    .tt-input__label {
        width: 100%;
        grid-column: 1;
    }

    .tt-input__group {
        width: 100%;
        grid-column: 2;
    }
}

.tt-input__base--label-top,
.tt-label-on-top .tt-input__base {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;

    & > .tt-input__label,
    & > .tt-input__group {
        width: 100%;
        grid-column: 1;
    }
}

.tt-input__base--label-none {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.tt-input__base--label-none > .tt-input__group {
    grid-column: 1;
    grid-row: 1;
}

.tt-input__base--label-none > .tt-input__label {
    display: none;
}

.tt-input__base--label-hidden > .tt-input__label {
    visibility: hidden;
}

.tt-input__message {
    padding: 1rem 1.6rem;
    background-color: var(--tt-input__message--color);
    color: var(--tt-input__message--text-color);
    border-radius: var(--tt-border-radius);
    box-shadow: none;
    border: none;
}

.tt-input__message--current-user {
    background-color: rgba(var(--tt-primary-color-rgb), 0.9);
    color: #fff;
}

.input-range {
    display: block;
    border-top: 4px;
    height: 22px;
    line-height: 22px;
    padding: 4px 4px;
    font-size: 14px;
    margin-bottom: 5px;
    clear: both;
}

.input-range span:nth-of-type(1) {
    float: right;
    font-size: 30px;
}

.input-range span:nth-of-type(2) {
    float: left;
    font-size: 10px;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    margin: 10px 0;
    width: 100%;
    background: transparent;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    /* animate: 0.2s; */
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: rgb(196, 196, 196);
    border-radius: 25px;
    border: 0px solid #000101;
}

input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 150%;
    width: 10%;
    border-radius: 7px;
    background: black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.6px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
    background: rgb(196, 196, 196);
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    /* animate: 0.2s; */
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: rgb(196, 196, 196);
    border-radius: 25px;
    border: 0px solid #000101;
}

input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 150%;
    width: 10%;
    border-radius: 7px;
    background: rgb(196, 196, 196);
    cursor: pointer;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    /* animate: 0.2s; */
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: rgb(196, 196, 196);
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
    background: rgb(196, 196, 196);
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

input[type="range"]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 150%;
    width: 10%;
    border-radius: 7px;
    background: rgb(196, 196, 196);
    cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
    background: rgb(196, 196, 196);
}

input[type="range"]:focus::-ms-fill-upper {
    background: rgb(196, 196, 196);
}

table.k-editor {
    min-height: 200px;
    height: 100%;
}
