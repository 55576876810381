:root {
    --im-placeholder: 0.75;
}
    :root.dark {
    --im-placeholder: 1;
}
/** FROM HERE: Only used by multi checklist */
.im-layouts-text {
    outline: none;
    background: transparent;
    color: var(--tt-body-text-color);
}

    .im-layouts-text:placeholder-shown {
        font-style: italic;
        opacity: var(--im-placeholder);
    }

.im-modal-dynamic-table {
    max-height: 400px;
    display: block;
    overflow-y: scroll;
    margin-bottom: 6px;
}

/** TO HERE: Only used by multi checklist */
