
/* mouse over dropdown-toggle */
.dropdown:hover .dropdown-menu {
    display: block;
}

.btn-group:hover .dropdown-menu {
    display: block;
}

[uib-typeahead-popup].dropdown-menu {
    font-size: var(--im-uib-typeahead-fontsize, 14px);
    padding: 2px 0;
}

.dropdown-menu > li > a {
    padding: 2px 8px;
}

.dropdown-menu {
    z-index: 1075;
}
