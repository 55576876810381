:root {
    --tt-input--color: #000;
    --tt-input--background: #f4f4f4;
    --tt-input--border-color: #8d8d8d;
    --tt-input--background--readonly: transparent;
    --tt-input--border-color--readonly: #e0e0e0;
    --tt-input--color--disabled: #c6c6c6;
    --tt-input--background--disabled: #f4f4f4;
    --tt-input--border-color--disabled: transparent;
    --tt-input__label--color: #555;
    --tt-input__label--text-transform: uppercase;
    --tt-input__label--font-weight: 700;
    --tt-input__sublabel--color: dimgray;
    --tt-input__group--box-shadow: none;
    --tt-input__form-control--box-shadow: none;
    --tt-input__form-control--border-left: none;
    --tt-input__form-control--border-top: none;
    --tt-input__form-control--border-right: none;
    --tt-input__form-control--border-bottom: 0.1rem solid var(--tt-input--border-color);
    --tt-input__form-control--border-left--readonly: none;
    --tt-input__form-control--border-top--readonly: none;
    --tt-input__form-control--border-right--readonly: none;
    --tt-input__form-control--border-bottom--readonly: 0.1rem solid var(--tt-input--border-color--readonly);
    --tt-input__form-control--border-left--disabled: none;
    --tt-input__form-control--border-top--disabled: none;
    --tt-input__form-control--border-right--disabled: none;
    --tt-input__form-control--border-bottom--disabled: 0.1rem solid var(--tt-input--border-color--disabled);
    --tt-input--border-top-left-radius: 0;
    --tt-input--border-top-right-radius: 0;
    --tt-input--border-bottom-left-radius: 0;
    --tt-input--border-bottom-right-radius: 0;
    --tt-input__checkbox--color: #161616;
    --tt-input__checkbox--color--checked: #fff;
    --tt-input__checkbox--border: 0.1rem solid var(--tt-input__checkbox--color);
    --tt-input__checkbox--border-radius: 2px;
    --tt-input__date-range--color: var(--tt-secondary-color);
    --tt-input__message--color: #f4f4f4;
    --tt-input__message--text-color: #161616;
    --tt-input__date-range--color--hover: rgba(var(--tt-secondary-color-rgb), 0.1);
    --tt-input__date-range--text-color: var(--tt-secondary-color-text);
    --tt-input__date-range--text-color--active: #fff;
    --tt-input--chevron--url: url('../../../assets/images/icons/svg/chevron-down-light.svg');
}


    :root.dark {
        --tt-input--color: #f4f4f4;
        --tt-input--background: #2b2b2b;
        --tt-input--border-color: #6f6f6f;
        --tt-input--background--readonly: transparent;
        --tt-input--border-color--readonly: #3f3f3f;
        --tt-input--color--disabled: #525252;
        --tt-input--background--disabled: #262626;
        --tt-input--border-color--disabled: transparent;
        --tt-input__label--color: #c6c6c6;
        --tt-input__label--text-transform: uppercase;
        --tt-input__label--font-weight: 700;
        --tt-input__sublabel--color: #9b9b9b;
        --tt-input__group--box-shadow: none;
        --tt-input__form-control--box-shadow: none;
        --tt-input__form-control--border-left: none;
        --tt-input__form-control--border-top: none;
        --tt-input__form-control--border-right: none;
        --tt-input__form-control--border-bottom: 0.1rem solid var(--tt-input--border-color);
        --tt-input__form-control--border-left--readonly: none;
        --tt-input__form-control--border-top--readonly: none;
        --tt-input__form-control--border-right--readonly: none;
        --tt-input__form-control--border-bottom--readonly: 0.1rem solid var(--tt-input--border-color--readonly);
        --tt-input__form-control--border-left--disabled: none;
        --tt-input__form-control--border-top--disabled: none;
        --tt-input__form-control--border-right--disabled: none;
        --tt-input__form-control--border-bottom--disabled: 0.1rem solid var(--tt-input--border-color--disabled);
        --tt-input--border-top-left-radius: 0;
        --tt-input--border-top-right-radius: 0;
        --tt-input--border-bottom-left-radius: 0;
        --tt-input--border-bottom-right-radius: 0;
        --tt-input__checkbox--color: #f4f4f4;
        --tt-input__checkbox--color--checked: #161616;
        --tt-input__checkbox--border: 0.1rem solid var(--tt-input__checkbox--color);
        --tt-input__checkbox--border-radius: 2px;
        --tt-input__message--color: #444;
        --tt-input__message--text-color: #fff;
        --tt-input__date-range--color: #f4f4f4;
        --tt-input__date-range--color--hover: rgba(244, 244, 244, 0.3);
        --tt-input__date-range--text-color: #f4f4f4;
        --tt-input__date-range--text-color--active: #161616;
        --tt-input--chevron--url: url('../../../assets/images/icons/svg/chevron-down-light-white.svg');
    }
