:root {
    --tt-list--color: #ffffff;
    --tt-list--text-color: #333333;
    --tt-list--border-color: #CCCCCC;
    --tt-list--color--hover: #e6e6e6;
    --tt-list--text-color--secondary: #B8B8B8;
}

    :root.dark {
        --tt-list--color: var(--tt-card--color);
        --tt-list--text-color: #dedede;
        --tt-list--border-color: #333;
        --tt-list--color--hover: #2d2d2d;
        --tt-list--text-color--secondary: #B8B8B8;
    }

.im-list-btn--flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.im-newtab {
    position: absolute;
    right: 12px;
    top: 0px;
    padding: 0.7em;
    border: none;
    background: transparent;
    color: var(--tt-list--text-color--secondary);
    z-index: 1;
}

.im-chevron {
    color: var(--tt-list--text-color--secondary);
    text-align: right;
    padding: 0;
}

.im-chevron-2 {
    top: 50%;
    transform: translateY(50%);
    color: var(--tt-list--text-color--secondary);
    text-align: right;
    padding: 0;
}

.im-ct {
    content-visibility: auto;
}

.btn-default .im-list-badge {
    background-color: #64AAE6;
}

.listview {
    position: relative;
    text-align: left;
    padding: 0.5em 0.2em 0.5em 0.2em;
    padding-left: 9px;
    color: var(--tt-list--text-color);
    background: var(--tt-list--color);
    border-color: var(--tt-list--color) var(--tt-list--color) var(--tt-list--border-color);
    text-shadow: none;
    border-radius: 0;
    transition: all 0.1s ease-in;
    box-shadow: none;
}

button.listview:hover,
.btn-default.listview:hover {
    background: var(--tt-list--color--hover);
    border-color: var(--tt-list--color--hover) var(--tt-list--color--hover) var(--tt-list--border-color);
    color: var(--tt-list--text-color);
}

button.listview:focus, button.listview:active, button.listview:active:focus,
.btn-default.listview:focus, .btn-default.listview:active, .btn-default.listview:active:focus {
    background: var(--tt-list--color--hover);
    border-color: var(--tt-list--color--hover) var(--tt-list--color--hover) var(--tt-list--border-color);
    color: var(--tt-list--text-color);
    outline: 0.2rem solid var(--tt-focus-color);
    outline-offset: -0.2rem;
}

.listview_margin {
    text-align: left;
    padding: 0.5em 0 0.5em 0.5em;
    color: var(--tt-list--text-color);
    background: var(--tt-list--color);
    border-color: var(--tt-list--color) var(--tt-list--color) var(--tt-list--border-color);
    border-radius: 0;
}

.listview_text {
    overflow: hidden;
    padding-left: 5px;
}

.listview_arrow {
    overflow: hidden;
    padding: 0;
    font-size: 20px;
}

.listview-selected {
    background: #e0e0e0;
    border-color: #adadad;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.listview-backbutton, .listview-backbutton:focus, .listview-backbutton:hover {
    background: #e0e0e0;
    border-color: #e0e0e0;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.listview-homebutton, .listview-homebutton:focus, .listview-homebutton:hover {
    background: #e0e0e0;
    border-color: #e0e0e0;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.listview-spin {
    padding: 0;
    margin-right: 10px;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 9px;
    top: 6px;
}

.noUi-handle:after {
    left: 12px;
}

.noUi-horizontal .noUi-handle {
    width: 24px;
    height: 28px;
    right: -12px;
    top: -6px;
}

.no-h {
    pointer-events: none;
}

.multi-list-col-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
}

.multi-list-btn {
    pointer-events: all;
    color: white;
    top: 0;
    width: 38px;
    padding: 8px;
}

.multi-list-menu-btn {
    color: white;
    top: 0;
    width: 38px;
    padding: 8px;
    margin-left: 15px;
    height: 36px;
}

.multi-list-menu-icon {
    color: black; /*#eb9316*/
    top: 0;
    width: 38px;
    padding: 8px;
    margin-left: 15px;
    height: 36px;
    border: 0px;
}

.multi-list-menu-icon-warning {
    color: #eb9316;
    top: 0;
    width: 38px;
    padding: 8px;
    margin-left: 15px;
    height: 36px;
    border: 0px;
}

.multi-list-menu-icon-success {
    color: #5cb85c;
    top: 0;
    width: 38px;
    padding: 8px;
    margin-left: 15px;
    height: 36px;
    border: 0px;
}

.not-sortable {
    cursor: default !important;
}

.sort-list-container {
    min-width: 16.66666667%;
    min-height: 50px;
}

.sort-list-item {
    margin: 0 !important;
    cursor: move;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: none;
}

.sort-list-placeholder {
    position: relative;
    top: 5px;
    padding: 15px;
    margin: 12px 0;
    border: 1px solid #e3e3e3;
    background-color: lightgrey;
    cursor: move;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sortable__label {
    color: inherit;
}