
.sidepanel {
    z-index: 1;
    background-color: #f9f9f9;
    overflow-x: hidden;
    transition: 0.5s;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.sidepanel, .full {
    z-index: 0;
}

.sidepanel-empty {
    border: 0px solid #ccc;
    border-radius: 0px;
}

.sidepanel div {
    padding: 0px 0px 0px 0px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
}

.sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
}

.sidepanel .open {
    width: inherit;
}

.sidepanel .closed {
    width: 0;
}

.sidepanel-trans {
    position: absolute;
}

.sidepanel-full {
    padding-left: 10px;
}

.k-treeview .k-in {
    font-weight: 600;
    color: var(--tt-card--color-text);
}

.k-treeview .k-state-selected.k-in {
    font-weight: 700;
    color: var(--tt-primary-text-color);
}

.k-treeview .k-state-selected{
    background: var(--tt-primary-color);
}

.k-treeview .k-in.k-state-hover {
    background: rgba(var(--tt-primary-color-rgb), 0.3);
    /*border-color: var(--tt-primary-color);*/
}

.k-treeview.name-label {
    overflow-y: auto!important;
}
