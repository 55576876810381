

/* FROM HERE: ONLY USED IN MAP DIRECTIVE. */
.im-kendo-indicator-wrap {
    position: absolute;
    bottom: 20px;
    right: 0;
}

.im-kendo-indicator {
    background: #bbb;
    height: 5px;
}

.im-kendo-indicator-text {
    color: #444;
    text-align: right;
}

.im-k-control-layers-expanded {
    display: block;
    position: relative;
    padding: 6px 10px 6px 6px;
    margin: 12px;
    color: #333;
    box-shadow: 0 1px 5px rgba(0,0,0,0.4);
    background: #FEFEFE;
    border-radius: 5px;
}

.im-k-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 2px;
    width: 13px;
}

.im-k-control-layers-separator {
    height: 0;
    border-top: 1px solid #ddd;
    margin: 5px -10px 5px -6px;
}

.im-k-control-circle {
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.im-k-control-circle-2 {
    pointer-events: none;
    top: 50%;
    left: 50%;
}

.im-k-control-target {
    color: black;
    pointer-events: none;
    top: 50%;
    left: 50%;
}

.im-k-target {
    width: 32px;
    margin-left: -16px;
    margin-top: -36px;
}

.im-k-custom-map-btn {
    margin: 10px;
    height: 32px;
    width: 32px;
}

.im-k-custom-map-btn-icon {
    position: initial;
}

.im-k-icon-white {
    color: #fff;
}

.im-k-icon-red {
    color: #d9534f;
}
/* TO HERE: ONLY USED IN MAP DIRECTIVE. */
