.im-boxes {
    width: 100%;
    height: 600px;
    min-height: 125px;
    position: relative;
    display: -webkit-box;
    display: grid;
    display: inline-block;
    overflow: hidden;
    line-height: 0;
    touch-action: none;
}

.im-boxes-divider-h {
    height: 5px;
    position: relative;
    display: inline-block;
    z-index: 10;
    width: inherit;
    cursor: row-resize;
    border-radius: 3px;
    -webkit-box-shadow: var(--tt-boxes--shadow);
    box-shadow: var(--tt-boxes--shadow);
    background: var(--tt-boxes--color);
}

.im-boxes-divider-v {
    height: 100%;
    position: relative;
    display: inline-block;
    z-index: 10;
    width: 5px;
    cursor: col-resize;
    border-radius: 3px;
    -webkit-box-shadow: var(--tt-boxes--shadow);
    box-shadow: var(--tt-boxes--shadow);
    background: var(--tt-boxes--color);
}

    .im-boxes-divider-h:first-child, .im-boxes-divider-v:first-child {
        cursor: auto;
        display: none;
    }

.im-boxes-box-h {
    width: 100%;
    height: calc(100% - 12px);
    position: relative;
    display: inline-block;
    overflow: auto;
    touch-action: none;
}

.im-boxes-box-v {
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
    overflow-y: auto;
    line-height: initial;
    touch-action: none;
}

.im-ellipsis-h {
    text-align: center;
    width: inherit;
    height: inherit;
    line-height: 0.5;
    color: var(--tt-boxes--color-text);
}

.im-ellipsis-v {
    position: absolute;
    text-align: center;
    width: inherit;
    height: 100%;
    writing-mode: vertical-rl;
    padding: 9px;
    color: var(--tt-boxes--color-text);
}
