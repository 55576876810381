:root {
    --tt-listbox--color: var(--tt-card--color);
    --tt-listbox--text-color: var(--tt-card--color-text);
    --tt-listbox--color--hover: rgba(var(--tt-primary-color-rgb), 0.08);
}

:root.dark {
    --tt-listbox--color: #333;
    --tt-listbox--text-color: var(--tt-card--color-text);
    --tt-listbox--color--hover: rgba(255, 255, 255, 0.1);
}

/*.tt-listbox__outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    pointer-events: none;
}*/

.tt-listbox__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    filter: drop-shadow(0px 5px 9px rgba(1, 8, 18, 0.4));
    pointer-events: none;
    z-index: 10000;
}

.tt-listbox__modal {
    position: fixed;
    top: 3.8rem;
    right: 10px;
    width: 520px;
    background-color: rgb(255, 255, 255);
    background-color: var(--tt-listbox--color);
    border-radius: var(--tt-border-radius);
    pointer-events: all;
    max-height: 0;
    max-width: 90vw;
    overflow-y: scroll;
    z-index: 2147483647;
    transition: all 0.2s ease-in;
}

.tt-listbox__modal--hidden {
    max-height: 0;
    pointer-events: none;
}


.tt-listbox__modal--show {
    max-height: 100vh;
    pointer-events: all;
}


@media (min-width: 768px) {
    .tt-listbox__modal--show {
        max-height: 40vh;
        pointer-events: all;
    }
}

.tt-listbox__list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    overflow: hidden;
}

.tt-listbox__item {
    position: relative;
    color: black;
    color: var(--tt-listbox--text-color);
    padding: 0.8rem 1.6rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.1s ease-in;
    cursor: pointer;
}

    .tt-listbox__item::before {
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        width: 0.3rem;
        background-color: var(--tt-primary-color);
        opacity: 0;
        transition: all 0.1s ease-in;
    }

    .tt-listbox__item:hover,
    .tt-listbox__item:focus,
    .tt-listbox__item:focus-visible,
    .tt-listbox__item--selected {
        background-color: var(--tt-listbox--color--hover);
        text-decoration: underline;
        color: var(--tt-listbox--text-color);
        outline: none;
    }

        .tt-listbox__item--selected::before {
            opacity: 1;
        }
