.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }

@media (min-width: 1200px) {
    .visible-lg {
        display: none !important;
    }

    table.visible-lg {
        display: none !important;
    }

    tr.visible-lg {
        display: none !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: none !important;
    }
}
@media (min-width: 1200px) and (max-width: 1439px) {
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table !important;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
  .col-xs-pull-12 {
    right: 100%;
  }
  .col-xs-pull-11 {
    right: 91.66666667%;
  }
  .col-xs-pull-10 {
    right: 83.33333333%;
  }
  .col-xs-pull-9 {
    right: 75%;
  }
  .col-xs-pull-8 {
    right: 66.66666667%;
  }
  .col-xs-pull-7 {
    right: 58.33333333%;
  }
  .col-xs-pull-6 {
    right: 50%;
  }
  .col-xs-pull-5 {
    right: 41.66666667%;
  }
  .col-xs-pull-4 {
    right: 33.33333333%;
  }
  .col-xs-pull-3 {
    right: 25%;
  }
  .col-xs-pull-2 {
    right: 16.66666667%;
  }
  .col-xs-pull-1 {
    right: 8.33333333%;
  }
  .col-xs-pull-0 {
    right: auto;
  }
  .col-xs-push-12 {
    left: 100%;
  }
  .col-xs-push-11 {
    left: 91.66666667%;
  }
  .col-xs-push-10 {
    left: 83.33333333%;
  }
  .col-xs-push-9 {
    left: 75%;
  }
  .col-xs-push-8 {
    left: 66.66666667%;
  }
  .col-xs-push-7 {
    left: 58.33333333%;
  }
  .col-xs-push-6 {
    left: 50%;
  }
  .col-xs-push-5 {
    left: 41.66666667%;
  }
  .col-xs-push-4 {
    left: 33.33333333%;
  }
  .col-xs-push-3 {
    left: 25%;
  }
  .col-xs-push-2 {
    left: 16.66666667%;
  }
  .col-xs-push-1 {
    left: 8.33333333%;
  }
  .col-xs-push-0 {
    left: auto;
  }
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xs-offset-0 {
    margin-left: 0;
  }
  @media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: left;
    }
    .col-sm-12 {
      width: 100%;
    }
    .col-sm-11 {
      width: 91.66666667%;
    }
    .col-sm-10 {
      width: 83.33333333%;
    }
    .col-sm-9 {
      width: 75%;
    }
    .col-sm-8 {
      width: 66.66666667%;
    }
    .col-sm-7 {
      width: 58.33333333%;
    }
    .col-sm-6 {
      width: 50%;
    }
    .col-sm-5 {
      width: 41.66666667%;
    }
    .col-sm-4 {
      width: 33.33333333%;
    }
    .col-sm-3 {
      width: 25%;
    }
    .col-sm-2 {
      width: 16.66666667%;
    }
    .col-sm-1 {
      width: 8.33333333%;
    }
    .col-sm-pull-12 {
      right: 100%;
    }
    .col-sm-pull-11 {
      right: 91.66666667%;
    }
    .col-sm-pull-10 {
      right: 83.33333333%;
    }
    .col-sm-pull-9 {
      right: 75%;
    }
    .col-sm-pull-8 {
      right: 66.66666667%;
    }
    .col-sm-pull-7 {
      right: 58.33333333%;
    }
    .col-sm-pull-6 {
      right: 50%;
    }
    .col-sm-pull-5 {
      right: 41.66666667%;
    }
    .col-sm-pull-4 {
      right: 33.33333333%;
    }
    .col-sm-pull-3 {
      right: 25%;
    }
    .col-sm-pull-2 {
      right: 16.66666667%;
    }
    .col-sm-pull-1 {
      right: 8.33333333%;
    }
    .col-sm-pull-0 {
      right: auto;
    }
    .col-sm-push-12 {
      left: 100%;
    }
    .col-sm-push-11 {
      left: 91.66666667%;
    }
    .col-sm-push-10 {
      left: 83.33333333%;
    }
    .col-sm-push-9 {
      left: 75%;
    }
    .col-sm-push-8 {
      left: 66.66666667%;
    }
    .col-sm-push-7 {
      left: 58.33333333%;
    }
    .col-sm-push-6 {
      left: 50%;
    }
    .col-sm-push-5 {
      left: 41.66666667%;
    }
    .col-sm-push-4 {
      left: 33.33333333%;
    }
    .col-sm-push-3 {
      left: 25%;
    }
    .col-sm-push-2 {
      left: 16.66666667%;
    }
    .col-sm-push-1 {
      left: 8.33333333%;
    }
    .col-sm-push-0 {
      left: auto;
    }
    .col-sm-offset-12 {
      margin-left: 100%;
    }
    .col-sm-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-sm-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-sm-offset-9 {
      margin-left: 75%;
    }
    .col-sm-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-sm-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-sm-offset-6 {
      margin-left: 50%;
    }
    .col-sm-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-sm-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-sm-offset-3 {
      margin-left: 25%;
    }
    .col-sm-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-sm-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-sm-offset-0 {
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
      float: left;
    }
    .col-md-12 {
      width: 100%;
    }
    .col-md-11 {
      width: 91.66666667%;
    }
    .col-md-10 {
      width: 83.33333333%;
    }
    .col-md-9 {
      width: 75%;
    }
    .col-md-8 {
      width: 66.66666667%;
    }
    .col-md-7 {
      width: 58.33333333%;
    }
    .col-md-6 {
      width: 50%;
    }
    .col-md-5 {
      width: 41.66666667%;
    }
    .col-md-4 {
      width: 33.33333333%;
    }
    .col-md-3 {
      width: 25%;
    }
    .col-md-2 {
      width: 16.66666667%;
    }
    .col-md-1 {
      width: 8.33333333%;
    }
    .col-md-pull-12 {
      right: 100%;
    }
    .col-md-pull-11 {
      right: 91.66666667%;
    }
    .col-md-pull-10 {
      right: 83.33333333%;
    }
    .col-md-pull-9 {
      right: 75%;
    }
    .col-md-pull-8 {
      right: 66.66666667%;
    }
    .col-md-pull-7 {
      right: 58.33333333%;
    }
    .col-md-pull-6 {
      right: 50%;
    }
    .col-md-pull-5 {
      right: 41.66666667%;
    }
    .col-md-pull-4 {
      right: 33.33333333%;
    }
    .col-md-pull-3 {
      right: 25%;
    }
    .col-md-pull-2 {
      right: 16.66666667%;
    }
    .col-md-pull-1 {
      right: 8.33333333%;
    }
    .col-md-pull-0 {
      right: auto;
    }
    .col-md-push-12 {
      left: 100%;
    }
    .col-md-push-11 {
      left: 91.66666667%;
    }
    .col-md-push-10 {
      left: 83.33333333%;
    }
    .col-md-push-9 {
      left: 75%;
    }
    .col-md-push-8 {
      left: 66.66666667%;
    }
    .col-md-push-7 {
      left: 58.33333333%;
    }
    .col-md-push-6 {
      left: 50%;
    }
    .col-md-push-5 {
      left: 41.66666667%;
    }
    .col-md-push-4 {
      left: 33.33333333%;
    }
    .col-md-push-3 {
      left: 25%;
    }
    .col-md-push-2 {
      left: 16.66666667%;
    }
    .col-md-push-1 {
      left: 8.33333333%;
    }
    .col-md-push-0 {
      left: auto;
    }
    .col-md-offset-12 {
      margin-left: 100%;
    }
    .col-md-offset-11 {
      margin-left: 91.66666667%;
    }
    .col-md-offset-10 {
      margin-left: 83.33333333%;
    }
    .col-md-offset-9 {
      margin-left: 75%;
    }
    .col-md-offset-8 {
      margin-left: 66.66666667%;
    }
    .col-md-offset-7 {
      margin-left: 58.33333333%;
    }
    .col-md-offset-6 {
      margin-left: 50%;
    }
    .col-md-offset-5 {
      margin-left: 41.66666667%;
    }
    .col-md-offset-4 {
      margin-left: 33.33333333%;
    }
    .col-md-offset-3 {
      margin-left: 25%;
    }
    .col-md-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-md-offset-1 {
      margin-left: 8.33333333%;
    }
    .col-md-offset-0 {
      margin-left: 0;
    }
  }
@media (min-width: 1200px) and (max-width: 1439px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-11 {
        width: 91.66666667%;
    }

    .col-lg-10 {
        width: 83.33333333%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-8 {
        width: 66.66666667%;
    }

    .col-lg-7 {
        width: 58.33333333%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-5 {
        width: 41.66666667%;
    }

    .col-lg-4 {
        width: 33.33333333%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-2 {
        width: 16.66666667%;
    }

    .col-lg-1 {
        width: 8.33333333%;
    }

    .col-lg-pull-12 {
        right: 100%;
    }

    .col-lg-pull-11 {
        right: 91.66666667%;
    }

    .col-lg-pull-10 {
        right: 83.33333333%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-8 {
        right: 66.66666667%;
    }

    .col-lg-pull-7 {
        right: 58.33333333%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-5 {
        right: 41.66666667%;
    }

    .col-lg-pull-4 {
        right: 33.33333333%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-2 {
        right: 16.66666667%;
    }

    .col-lg-pull-1 {
        right: 8.33333333%;
    }

    .col-lg-pull-0 {
        right: auto;
    }

    .col-lg-push-12 {
        left: 100%;
    }

    .col-lg-push-11 {
        left: 91.66666667%;
    }

    .col-lg-push-10 {
        left: 83.33333333%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-8 {
        left: 66.66666667%;
    }

    .col-lg-push-7 {
        left: 58.33333333%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-5 {
        left: 41.66666667%;
    }

    .col-lg-push-4 {
        left: 33.33333333%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-2 {
        left: 16.66666667%;
    }

    .col-lg-push-1 {
        left: 8.33333333%;
    }

    .col-lg-push-0 {
        left: auto;
    }

    .col-lg-offset-12 {
        margin-left: 100%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }
}

/*
* CSS file extending Bootstrap 3 grid classes for screens bigger than 1440 (XL) and 1920 (XXL) px. Add this file after the Bootstrap CSS file and you will be able to use col-xl- and col-xxl-
*
* Author: Iggy Pritzker
* Company: WebProm Design
* Site: www.web-design.co.il
*/


@media (min-width: 1440px) and (max-width: 1919px) {
    .container {
        width: 1400px;
    }
}

@media (min-width: 1920px) {
    .container {
        width: 1860px;
    }
}

.col-xl-1, .col-xxl-1, .col-xl-2, .col-xxl-2, .col-xl-3, .col-xxl-3, .col-xl-4, .col-xxl-4, .col-xl-5, .col-xxl-5, .col-xl-6, .col-xxl-6, .col-xl-7, .col-xxl-7, .col-xl-8, .col-xxl-8, .col-xl-9, .col-xxl-9, .col-xl-10, .col-xxl-10, .col-xl-11, .col-xxl-11, .col-xl-12, .col-xxl-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-11 {
        width: 91.66666667%;
    }

    .col-xl-10 {
        width: 83.33333333%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-8 {
        width: 66.66666667%;
    }

    .col-xl-7 {
        width: 58.33333333%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-5 {
        width: 41.66666667%;
    }

    .col-xl-4 {
        width: 33.33333333%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-2 {
        width: 16.66666667%;
    }

    .col-xl-1 {
        width: 8.33333333%;
    }

    .col-xl-pull-12 {
        right: 100%;
    }

    .col-xl-pull-11 {
        right: 91.66666667%;
    }

    .col-xl-pull-10 {
        right: 83.33333333%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-8 {
        right: 66.66666667%;
    }

    .col-xl-pull-7 {
        right: 58.33333333%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-5 {
        right: 41.66666667%;
    }

    .col-xl-pull-4 {
        right: 33.33333333%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-2 {
        right: 16.66666667%;
    }

    .col-xl-pull-1 {
        right: 8.33333333%;
    }

    .col-xl-pull-0 {
        right: auto;
    }

    .col-xl-push-12 {
        left: 100%;
    }

    .col-xl-push-11 {
        left: 91.66666667%;
    }

    .col-xl-push-10 {
        left: 83.33333333%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-8 {
        left: 66.66666667%;
    }

    .col-xl-push-7 {
        left: 58.33333333%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-5 {
        left: 41.66666667%;
    }

    .col-xl-push-4 {
        left: 33.33333333%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xl-push-2 {
        left: 16.66666667%;
    }

    .col-xl-push-1 {
        left: 8.33333333%;
    }

    .col-xl-push-0 {
        left: auto;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xl-offset-0 {
        margin-left: 0;
    }
}

@media (min-width: 1920px) {
    .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 {
        float: left;
    }

    .col-xxl-12 {
        width: 100%;
    }

    .col-xxl-11 {
        width: 91.66666667%;
    }

    .col-xxl-10 {
        width: 83.33333333%;
    }

    .col-xxl-9 {
        width: 75%;
    }

    .col-xxl-8 {
        width: 66.66666667%;
    }

    .col-xxl-7 {
        width: 58.33333333%;
    }

    .col-xxl-6 {
        width: 50%;
    }

    .col-xxl-5 {
        width: 41.66666667%;
    }

    .col-xxl-4 {
        width: 33.33333333%;
    }

    .col-xxl-3 {
        width: 25%;
    }

    .col-xxl-2 {
        width: 16.66666667%;
    }

    .col-xxl-1 {
        width: 8.33333333%;
    }

    .col-xxl-pull-12 {
        right: 100%;
    }

    .col-xxl-pull-11 {
        right: 91.66666667%;
    }

    .col-xxl-pull-10 {
        right: 83.33333333%;
    }

    .col-xxl-pull-9 {
        right: 75%;
    }

    .col-xxl-pull-8 {
        right: 66.66666667%;
    }

    .col-xxl-pull-7 {
        right: 58.33333333%;
    }

    .col-xxl-pull-6 {
        right: 50%;
    }

    .col-xxl-pull-5 {
        right: 41.66666667%;
    }

    .col-xxl-pull-4 {
        right: 33.33333333%;
    }

    .col-xxl-pull-3 {
        right: 25%;
    }

    .col-xxl-pull-2 {
        right: 16.66666667%;
    }

    .col-xxl-pull-1 {
        right: 8.33333333%;
    }

    .col-xxl-pull-0 {
        right: auto;
    }

    .col-xxl-push-12 {
        left: 100%;
    }

    .col-xxl-push-11 {
        left: 91.66666667%;
    }

    .col-xxl-push-10 {
        left: 83.33333333%;
    }

    .col-xxl-push-9 {
        left: 75%;
    }

    .col-xxl-push-8 {
        left: 66.66666667%;
    }

    .col-xxl-push-7 {
        left: 58.33333333%;
    }

    .col-xxl-push-6 {
        left: 50%;
    }

    .col-xxl-push-5 {
        left: 41.66666667%;
    }

    .col-xxl-push-4 {
        left: 33.33333333%;
    }

    .col-xxl-push-3 {
        left: 25%;
    }

    .col-xxl-push-2 {
        left: 16.66666667%;
    }

    .col-xxl-push-1 {
        left: 8.33333333%;
    }

    .col-xxl-push-0 {
        left: auto;
    }

    .col-xxl-offset-12 {
        margin-left: 100%;
    }

    .col-xxl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xxl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xxl-offset-9 {
        margin-left: 75%;
    }

    .col-xxl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xxl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xxl-offset-6 {
        margin-left: 50%;
    }

    .col-xxl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xxl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xxl-offset-3 {
        margin-left: 25%;
    }

    .col-xxl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xxl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xxl-offset-0 {
        margin-left: 0;
    }
}

.visible-xl,
.visible-xxl {
    display: none !important;
}

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xxl-block,
.visible-xxl-inline,
.visible-xxl-inline-block {
    display: none !important;
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .visible-xl {
        display: block !important;
    }

    table.visible-xl {
        display: table;
    }

    tr.visible-xl {
        display: table-row !important;
    }

    th.visible-xl,
    td.visible-xl {
        display: table-cell !important;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .visible-xl-block {
        display: block !important;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .visible-xl-inline {
        display: inline !important;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .visible-xl-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1920px) {
    .visible-xxl {
        display: block !important;
    }

    table.visible-xxl {
        display: table;
    }

    tr.visible-xxl {
        display: table-row !important;
    }

    th.visible-xxl,
    td.visible-xxl {
        display: table-cell !important;
    }
}

@media (min-width: 1920px) {
    .visible-xxl-block {
        display: block !important;
    }
}

@media (min-width: 1920px) {
    .visible-xxl-inline {
        display: inline !important;
    }
}

@media (min-width: 1920px) {
    .visible-xxl-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .hidden-lg-x {
        display: none !important;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .hidden-xl {
        display: none !important;
    }
}

@media (min-width: 1920px) {
    .hidden-xxl {
        display: none !important;
    }
}


.col-xx-0, .col-xx-0-25, .col-xx-0-5, .col-xx-0-75, .col-xx-1, .col-xx-1-25, .col-xx-1-5, .col-xx-1-75, .col-xx-2, .col-xx-2-25, .col-xx-2-5, .col-xx-2-75, .col-xx-3, .col-xx-3-25, .col-xx-3-5, .col-xx-3-75, .col-xx-4, .col-xx-4-25, .col-xx-4-5, .col-xx-4-75, .col-xx-5, .col-xx-5-25, .col-xx-5-5, .col-xx-5-75, .col-xx-6, .col-xx-6-25, .col-xx-6-5, .col-xx-6-75, .col-xx-7, .col-xx-7-25, .col-xx-7-5, .col-xx-7-75, .col-xx-8, .col-xx-8-25, .col-xx-8-5, .col-xx-8-75, .col-xx-9, .col-xx-9-25, .col-xx-9-5, .col-xx-9-75, .col-xx-10, .col-xx-10-25, .col-xx-10-5, .col-xx-10-75, .col-xx-11, .col-xx-11-25, .col-xx-11-5, .col-xx-11-75, .col-xx-12 {
    position: relative;
    min-height: 1px;
    float: left;
}

.col-xx-12 {
    width: 100%;
}

.col-xx-11-75 {
    width: 97.91666667%;
}

.col-xx-11-5 {
    width: 95.83333333%;
}

.col-xx-11-25 {
    width: 93.75%;
}

.col-xx-11 {
    width: 91.66666667%;
}

.col-xx-10-75 {
    width: 89.58333333%;
}

.col-xx-10-5 {
    width: 87.5%;
}

.col-xx-10-25 {
    width: 85.41666667%;
}

.col-xx-10 {
    width: 83.33333333%;
}

.col-xx-9-75 {
    width: 81.25%;
}

.col-xx-9-5 {
    width: 79.16666667%;
}

.col-xx-9-25 {
    width: 77.08333333%;
}

.col-xx-9 {
    width: 75%;
}

.col-xx-8-75 {
    width: 72.91666667%;
}

.col-xx-8-5 {
    width: 70.83333333%;
}

.col-xx-8-25 {
    width: 68.75%;
}

.col-xx-8 {
    width: 66.66666667%;
}

.col-xx-7-75 {
    width: 64.58333333%;
}

.col-xx-7-5 {
    width: 62.5%;
}

.col-xx-7-25 {
    width: 60.41666667%;
}

.col-xx-7 {
    width: 58.33333333%;
}

.col-xx-6-75 {
    width: 56.25%;
}

.col-xx-6-5 {
    width: 54.16666667%;
}

.col-xx-6-25 {
    width: 52.08333333%;
}

.col-xx-6 {
    width: 50%;
}

.col-xx-5-75 {
    width: 47.91666667%;
}

.col-xx-5-5 {
    width: 45.83333333%;
}

.col-xx-5-25 {
    width: 43.75%;
}

.col-xx-5 {
    width: 41.66666667%;
}

.col-xx-4-75 {
    width: 39.58333333%;
}

.col-xx-4-5 {
    width: 37.5%;
}

.col-xx-4-25 {
    width: 35.41666667%;
}

.col-xx-4 {
    width: 33.33333333%;
}

.col-xx-3-75 {
    width: 31.25%;
}

.col-xx-3-5 {
    width: 29.16666667%;
}

.col-xx-3-25 {
    width: 27.08333333%;
}

.col-xx-3 {
    width: 25%;
}

.col-xx-2-75 {
    width: 22.91666667%;
}

.col-xx-2-5 {
    width: 20.83333333%;
}

.col-xx-2-25 {
    width: 18.75%;
}

.col-xx-2 {
    width: 16.66666667%;
}

.col-xx-1-75 {
    width: 14.58333333%;
}

.col-xx-1-5 {
    width: 12.5%;
}

.col-xx-1-25 {
    width: 10.41666667%;
}

.col-xx-1 {
    width: 8.33333333%;
}

.col-xx-0-75 {
    width: 6.25%;
}

.col-xx-0-5 {
    width: 4.16666665%;
}

.col-xx-0-25 {
    width: 2.08333333%;
}

.col-xx-0 {
    width: 0%;
    padding: 0px;
}

@media (min-width: 384px) and (max-width: 767px) {
    .col-xs-0-25, .col-xs-0-5, .col-xs-0-75, .col-xs-1, .col-xs-1-25, .col-xs-1-5, .col-xs-1-75, .col-xs-2, .col-xs-2-25, .col-xs-2-5, .col-xs-2-75, .col-xs-3, .col-xs-3-25, .col-xs-3-5, .col-xs-3-75, .col-xs-4, .col-xs-4-25, .col-xs-4-5, .col-xs-4-75, .col-xs-5, .col-xs-5-25, .col-xs-5-5, .col-xs-5-75, .col-xs-6, .col-xs-6-25, .col-xs-6-5, .col-xs-6-75, .col-xs-7, .col-xs-7-25, .col-xs-7-5, .col-xs-7-75, .col-xs-8, .col-xs-8-25, .col-xs-8-5, .col-xs-8-75, .col-xs-9, .col-xs-9-25, .col-xs-9-5, .col-xs-9-75, .col-xs-10, .col-xs-10-25, .col-xs-10-5, .col-xs-10-75, .col-xs-11, .col-xs-11-25, .col-xs-11-5, .col-xs-11-75, .col-xs-12 {
        position: relative;
        min-height: 1px;
        float: left;
    }

    .col-xs-12 {
        width: 100%;
    }

    .col-xs-11-75 {
        width: 97.91666667%;
    }

    .col-xs-11-5 {
        width: 95.83333333%;
    }

    .col-xs-11-25 {
        width: 93.75%;
    }

    .col-xs-11 {
        width: 91.66666667%;
    }

    .col-xs-10-75 {
        width: 89.58333333%;
    }

    .col-xs-10-5 {
        width: 87.5%;
    }

    .col-xs-10-25 {
        width: 85.41666667%;
    }

    .col-xs-10 {
        width: 83.33333333%;
    }

    .col-xs-9-75 {
        width: 81.25%;
    }

    .col-xs-9-5 {
        width: 79.16666667%;
    }

    .col-xs-9-25 {
        width: 77.08333333%;
    }

    .col-xs-9 {
        width: 75%;
    }

    .col-xs-8-75 {
        width: 72.91666667%;
    }

    .col-xs-8-5 {
        width: 70.83333333%;
    }

    .col-xs-8-25 {
        width: 68.75%;
    }

    .col-xs-8 {
        width: 66.66666667%;
    }

    .col-xs-7-75 {
        width: 64.58333333%;
    }

    .col-xs-7-5 {
        width: 62.5%;
    }

    .col-xs-7-25 {
        width: 60.41666667%;
    }

    .col-xs-7 {
        width: 58.33333333%;
    }

    .col-xs-6-75 {
        width: 56.25%;
    }

    .col-xs-6-5 {
        width: 54.16666667%;
    }

    .col-xs-6-25 {
        width: 52.08333333%;
    }

    .col-xs-6 {
        width: 50%;
    }

    .col-xs-5-75 {
        width: 47.91666667%;
    }

    .col-xs-5-5 {
        width: 45.83333333%;
    }

    .col-xs-5-25 {
        width: 43.75%;
    }

    .col-xs-5 {
        width: 41.66666667%;
    }

    .col-xs-4-75 {
        width: 39.58333333%;
    }

    .col-xs-4-5 {
        width: 37.5%;
    }

    .col-xs-4-25 {
        width: 35.41666667%;
    }

    .col-xs-4 {
        width: 33.33333333%;
    }

    .col-xs-3-75 {
        width: 31.25%;
    }

    .col-xs-3-5 {
        width: 29.16666667%;
    }

    .col-xs-3-25 {
        width: 27.08333333%;
    }

    .col-xs-3 {
        width: 25%;
    }

    .col-xs-2-75 {
        width: 22.91666667%;
    }

    .col-xs-2-5 {
        width: 20.83333333%;
    }

    .col-xs-2-25 {
        width: 18.75%;
    }

    .col-xs-2 {
        width: 16.66666667%;
    }

    .col-xs-1-75 {
        width: 14.58333333%;
    }

    .col-xs-1-5 {
        width: 12.5%;
    }

    .col-xs-1-25 {
        width: 10.41666667%;
    }

    .col-xs-1 {
        width: 8.33333333%;
    }

    .col-xs-0-75 {
        width: 6.25%;
    }

    .col-xs-0-5 {
        width: 4.16666665%;
    }

    .col-xs-0-25 {
        width: 2.08333333%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .col-sm-0-25, .col-sm-0-5, .col-sm-0-75, .col-sm-1, .col-sm-1-25, .col-sm-1-5, .col-sm-1-75, .col-sm-2, .col-sm-2-25, .col-sm-2-5, .col-sm-2-75, .col-sm-3, .col-sm-3-25, .col-sm-3-5, .col-sm-3-75, .col-sm-4, .col-sm-4-25, .col-sm-4-5, .col-sm-4-75, .col-sm-5, .col-sm-5-25, .col-sm-5-5, .col-sm-5-75, .col-sm-6, .col-sm-6-25, .col-sm-6-5, .col-sm-6-75, .col-sm-7, .col-sm-7-25, .col-sm-7-5, .col-sm-7-75, .col-sm-8, .col-sm-8-25, .col-sm-8-5, .col-sm-8-75, .col-sm-9, .col-sm-9-25, .col-sm-9-5, .col-sm-9-75, .col-sm-10, .col-sm-10-25, .col-sm-10-5, .col-sm-10-75, .col-sm-11, .col-sm-11-25, .col-sm-11-5, .col-sm-11-75, .col-sm-12 {
        position: relative;
        min-height: 1px;
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-11-75 {
        width: 97.91666667%;
    }

    .col-sm-11-5 {
        width: 95.83333333%;
    }

    .col-sm-11-25 {
        width: 93.75%;
    }

    .col-sm-11 {
        width: 91.66666667%;
    }

    .col-sm-10-75 {
        width: 89.58333333%;
    }

    .col-sm-10-5 {
        width: 87.5%;
    }

    .col-sm-10-25 {
        width: 85.41666667%;
    }

    .col-sm-10 {
        width: 83.33333333%;
    }

    .col-sm-9-75 {
        width: 81.25%;
    }

    .col-sm-9-5 {
        width: 79.16666667%;
    }

    .col-sm-9-25 {
        width: 77.08333333%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-8-75 {
        width: 72.91666667%;
    }

    .col-sm-8-5 {
        width: 70.83333333%;
    }

    .col-sm-8-25 {
        width: 68.75%;
    }

    .col-sm-8 {
        width: 66.66666667%;
    }

    .col-sm-7-75 {
        width: 64.58333333%;
    }

    .col-sm-7-5 {
        width: 62.5%;
    }

    .col-sm-7-25 {
        width: 60.41666667%;
    }

    .col-sm-7 {
        width: 58.33333333%;
    }

    .col-sm-6-75 {
        width: 56.25%;
    }

    .col-sm-6-5 {
        width: 54.16666667%;
    }

    .col-sm-6-25 {
        width: 52.08333333%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-5-75 {
        width: 47.91666667%;
    }

    .col-sm-5-5 {
        width: 45.83333333%;
    }

    .col-sm-5-25 {
        width: 43.75%;
    }

    .col-sm-5 {
        width: 41.66666667%;
    }

    .col-sm-4-75 {
        width: 39.58333333%;
    }

    .col-sm-4-5 {
        width: 37.5%;
    }

    .col-sm-4-25 {
        width: 35.41666667%;
    }

    .col-sm-4 {
        width: 33.33333333%;
    }

    .col-sm-3-75 {
        width: 31.25%;
    }

    .col-sm-3-5 {
        width: 29.16666667%;
    }

    .col-sm-3-25 {
        width: 27.08333333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2-75 {
        width: 22.91666667%;
    }

    .col-sm-2-5 {
        width: 20.83333333%;
    }

    .col-sm-2-25 {
        width: 18.75%;
    }

    .col-sm-2 {
        width: 16.66666667%;
    }

    .col-sm-2-f {
        min-width: 16.66666667%;
    }

    .col-sm-1-75 {
        width: 14.58333333%;
    }

    .col-sm-1-5 {
        width: 12.5%;
    }

    .col-sm-1-25 {
        width: 10.41666667%;
    }

    .col-sm-1 {
        width: 8.33333333%;
    }

    .col-sm-0-75 {
        width: 6.25%;
    }

    .col-sm-0-5 {
        width: 4.16666665%;
    }

    .col-sm-0-25 {
        width: 2.08333333%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .pull-sm-right {
        float: right;
    }
}

@media (min-width: 992px) { /*and (max-width: 1199px) {*/
    .col-md-0-25, .col-md-0-5, .col-md-0-75, .col-md-1, .col-md-1-25, .col-md-1-5, .col-md-1-75, .col-md-2, .col-md-2-25, .col-md-2-5, .col-md-2-75, .col-md-3, .col-md-3-25, .col-md-3-5, .col-md-3-75, .col-md-4, .col-md-4-25, .col-md-4-5, .col-md-4-75, .col-md-5, .col-md-5-25, .col-md-5-5, .col-md-5-75, .col-md-6, .col-md-6-25, .col-md-6-5, .col-md-6-75, .col-md-7, .col-md-7-25, .col-md-7-5, .col-md-7-75, .col-md-8, .col-md-8-25, .col-md-8-5, .col-md-8-75, .col-md-9, .col-md-9-25, .col-md-9-5, .col-md-9-75, .col-md-10, .col-md-10-25, .col-md-10-5, .col-md-10-75, .col-md-11, .col-md-11-25, .col-md-11-5, .col-md-11-75, .col-md-12 {
        position: relative;
        min-height: 1px;
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-11-75 {
        width: 97.91666667%;
    }

    .col-md-11-5 {
        width: 95.83333333%;
    }

    .col-md-11-25 {
        width: 93.75%;
    }

    .col-md-11 {
        width: 91.66666667%;
    }

    .col-md-10-75 {
        width: 89.58333333%;
    }

    .col-md-10-5 {
        width: 87.5%;
    }

    .col-md-10-25 {
        width: 85.41666667%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .col-md-9-75 {
        width: 81.25%;
    }

    .col-md-9-5 {
        width: 79.16666667%;
    }

    .col-md-9-25 {
        width: 77.08333333%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8-75 {
        width: 72.91666667%;
    }

    .col-md-8-5 {
        width: 70.83333333%;
    }

    .col-md-8-25 {
        width: 68.75%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-7-75 {
        width: 64.58333333%;
    }

    .col-md-7-5 {
        width: 62.5%;
    }

    .col-md-7-25 {
        width: 60.41666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-6-75 {
        width: 56.25%;
    }

    .col-md-6-5 {
        width: 54.16666667%;
    }

    .col-md-6-25 {
        width: 52.08333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5-75 {
        width: 47.91666667%;
    }

    .col-md-5-5 {
        width: 45.83333333%;
    }

    .col-md-5-25 {
        width: 43.75%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-4-75 {
        width: 39.58333333%;
    }

    .col-md-4-5 {
        width: 37.5%;
    }

    .col-md-4-25 {
        width: 35.41666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3-75 {
        width: 31.25%;
    }

    .col-md-3-5 {
        width: 29.16666667%;
    }

    .col-md-3-25 {
        width: 27.08333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2-75 {
        width: 22.91666667%;
    }

    .col-md-2-5 {
        width: 20.83333333%;
    }

    .col-md-2-25 {
        width: 18.75%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-1-75 {
        width: 14.58333333%;
    }

    .col-md-1-5 {
        width: 12.5%;
    }

    .col-md-1-25 {
        width: 10.41666667%;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

    .col-md-0-75 {
        width: 6.25%;
    }

    .col-md-0-5 {
        width: 4.16666665%;
    }

    .col-md-0-25 {
        width: 2.08333333%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .pull-md-right {
        float: right;
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .col-lg-0-25, .col-lg-0-5, .col-lg-0-75, .col-lg-1, .col-lg-1-25, .col-lg-1-5, .col-lg-1-75, .col-lg-2, .col-lg-2-25, .col-lg-2-5, .col-lg-2-75, .col-lg-3, .col-lg-3-25, .col-lg-3-5, .col-lg-3-75, .col-lg-4, .col-lg-4-25, .col-lg-4-5, .col-lg-4-75, .col-lg-5, .col-lg-5-25, .col-lg-5-5, .col-lg-5-75, .col-lg-6, .col-lg-6-25, .col-lg-6-5, .col-lg-6-75, .col-lg-7, .col-lg-7-25, .col-lg-7-5, .col-lg-7-75, .col-lg-8, .col-lg-8-25, .col-lg-8-5, .col-lg-8-75, .col-lg-9, .col-lg-9-25, .col-lg-9-5, .col-lg-9-75, .col-lg-10, .col-lg-10-25, .col-lg-10-5, .col-lg-10-75, .col-lg-11, .col-lg-11-25, .col-lg-11-5, .col-lg-11-75, .col-lg-12 {
        position: relative;
        min-height: 1px;
        float: left;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-11-75 {
        width: 97.91666667%;
    }

    .col-lg-11-5 {
        width: 95.83333333%;
    }

    .col-lg-11-25 {
        width: 93.75%;
    }

    .col-lg-11 {
        width: 91.66666667%;
    }

    .col-lg-10-75 {
        width: 89.58333333%;
    }

    .col-lg-10-5 {
        width: 87.5%;
    }

    .col-lg-10-25 {
        width: 85.41666667%;
    }

    .col-lg-10 {
        width: 83.33333333%;
    }

    .col-lg-9-75 {
        width: 81.25%;
    }

    .col-lg-9-5 {
        width: 79.16666667%;
    }

    .col-lg-9-25 {
        width: 77.08333333%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-8-75 {
        width: 72.91666667%;
    }

    .col-lg-8-5 {
        width: 70.83333333%;
    }

    .col-lg-8-25 {
        width: 68.75%;
    }

    .col-lg-8 {
        width: 66.66666667%;
    }

    .col-lg-7-75 {
        width: 64.58333333%;
    }

    .col-lg-7-5 {
        width: 62.5%;
    }

    .col-lg-7-25 {
        width: 60.41666667%;
    }

    .col-lg-7 {
        width: 58.33333333%;
    }

    .col-lg-6-75 {
        width: 56.25%;
    }

    .col-lg-6-5 {
        width: 54.16666667%;
    }

    .col-lg-6-25 {
        width: 52.08333333%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-5-75 {
        width: 47.91666667%;
    }

    .col-lg-5-5 {
        width: 45.83333333%;
    }

    .col-lg-5-25 {
        width: 43.75%;
    }

    .col-lg-5 {
        width: 41.66666667%;
    }

    .col-lg-4-75 {
        width: 39.58333333%;
    }

    .col-lg-4-5 {
        width: 37.5%;
    }

    .col-lg-4-25 {
        width: 35.41666667%;
    }

    .col-lg-4 {
        width: 33.33333333%;
    }

    .col-lg-3-75 {
        width: 31.25%;
    }

    .col-lg-3-5 {
        width: 29.16666667%;
    }

    .col-lg-3-25 {
        width: 27.08333333%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-2-75 {
        width: 22.91666667%;
    }

    .col-lg-2-5 {
        width: 20.83333333%;
    }

    .col-lg-2-25 {
        width: 18.75%;
    }

    .col-lg-2 {
        width: 16.66666667%;
    }

    .col-lg-1-75 {
        width: 14.58333333%;
    }

    .col-lg-1-5 {
        width: 12.5%;
    }

    .col-lg-1-25 {
        width: 10.41666667%;
    }

    .col-lg-1 {
        width: 8.33333333%;
    }

    .col-lg-0-75 {
        width: 6.25%;
    }

    .col-lg-0-5 {
        width: 4.16666665%;
    }

    .col-lg-0-25 {
        width: 2.08333333%;
    }
}

.visible-xx,
.visible-xs {
    display: none !important;
}

.visible-xx-block,
.visible-xx-inline,
.visible-xx-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important;
}

@media (max-width: 383px) and (max-width: 767px) {
    .visible-xx {
        display: block !important;
    }

    table.visible-xx {
        display: table !important;
    }

    tr.visible-xx {
        display: table-row !important;
    }

    th.visible-xx,
    td.visible-xx {
        display: table-cell !important;
    }
}

@media (max-width: 383px) and (max-width: 767px) {
    .visible-xx-block {
        display: block !important;
    }
}

@media (max-width: 383px) and (max-width: 767px) {
    .visible-xx-inline {
        display: inline !important;
    }
}

@media (max-width: 383px) and (max-width: 767px) {
    .visible-xx-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 384px) and (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table !important;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (min-width: 384px) and (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (min-width: 384px) and (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (min-width: 384px) and (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}



@media (min-width: 1200px) and (max-width: 1439px) {
    .container {
        width: 1170px;
    }
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table !important;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
    .visible-lg-block {
        display: block !important;
    }
    .visible-lg-inline {
        display: inline !important;
    }
    .visible-lg-inline-block {
        display: inline-block !important;
    }
    .hidden-lg {
        display: none !important;
    }
}
