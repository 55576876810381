
.im-scheduler-buttons {
    float: left;
    padding: 0 10px;
}

.im-scheduler-btn {
    font-size: 18px;
    margin: 0 2px;
}
