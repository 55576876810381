:root {
    --tt-tabs--border-width: 0.25rem;
    --tt-tabs--color--hover: rgba(var(--tt-primary-color-rgb), 0.05);
    --tt-tabs--border-color: rgba(var(--tt-body-text-color-rgb), 0.1);
    --tt-tabs--border-color--hover: rgba(var(--tt-body-text-color-rgb), 0.3);
    --tt-tabs--border-color--active: var(--tt-primary-color);
}

.tt-tabs {
    display: flex;
    border-bottom: var(--tt-tabs--border-width) solid var(--tt-tabs--border-color);
}

.tt-tabs__button {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    background-color: transparent;
    border: none;
    padding: 8px 17px;
    min-width: 3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: calc(var(--tt-tabs--border-width) * -1);
    border-bottom: var(--tt-tabs--border-width) solid transparent;
    font-size: 1.2rem;
}

    .tt-tabs__button:hover {
        background-color: var(--tt-tabs--background--hover);
        cursor: pointer;
        border-bottom: var(--tt-tabs--border-width) solid var(--tt-tabs--border-color--hover);
    }

.tt-tabs__button--active, .tt-tabs__button--active:hover {
    font-weight: 600;
    border-bottom: var(--tt-tabs--border-width) solid var(--tt-tabs--border-color--active);
}

.tt-tabs__text {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .tt-tabs__button {
        flex: 1;
    }
}
