@import url("layout/_grid.css");
@import url("layout/_navigation.css");

@import url("base/_animations.css");
@import url("base/_base.css");
@import url("base/_typography.css");
@import url("base/_icons.css");

@import url("components/_buttons.css");
@import url("components/_dropdown.css");
@import url("components/_forms.css");
@import url("components/_file-uploader.css");
@import url("components/_multi-select.css");
@import url("components/_image.css");
@import url("components/_tabs.css");
@import url("components/_list.css");
@import url("components/_grid.css");
@import url("components/_card.css");
@import url("components/_maps.css");
@import url("components/_tree.css");
@import url("components/_comment.css");
@import url("components/_listbox.css");
@import url("components/_popover.css");
@import url("components/_overflow-menu.css");
@import url("components/_scheduler.css");
@import url("components/_boxes.css");
@import url("components/_carousel.css");
@import url("components/_alert.css");
@import url("components/_screen-block.css");
@import url("components/modals/_favourites.css");

@import url("overrides/_bootstrap.css");
@import url("overrides/_kendo.css");
@import url("overrides/_material.css");

@import url("views/_riskelement.css");
@import url("views/_pictures.css");
@import url("views/_email.css");

@import url("base/_utilities.css");
/* Core Grid CSS */
/* @import "ag-grid-community/styles/ag-grid.css"; */
/* Quartz Theme Specific CSS */
@import url("../../../node_modules/@ag-grid-community/styles/ag-grid.css");
@import url("../../../node_modules/@ag-grid-community/styles/ag-theme-quartz.css");
@import url("../../../node_modules/@ag-grid-community/styles/ag-theme-alpine.css");

.ag-theme-quartz,
.ag-theme-alpine-dark {
    --ag-grid-size: 5px;
    --ag-list-item-height: 20px;
}

:root {
    --tt-alt-color: var(--im-k-alt-bg-color, #fbfbfb);
}

:root.dark {
    --tt-alt-color: #1d1d1d;
}

.dark {
    color-scheme: dark;
}

.im-kendo-filter {
    margin: 20px;
    height: 34px;
    width: 34px;
}

.im-kendo-chart {
    height: 400px;
}

.im-print-preview {
    height: calc(100vh - 143px);
    margin-bottom: 10px;
}

.im-btn-group-v button {
    display: block; /* Make the buttons appear below each other */
}

.im-btn-group-v button:not(:last-child) {
    margin-bottom: 0;
}

.im-k-icon {
    font: 22px/1 WebComponentsIcons;
}

.im-k-icon-none,
.im-k-icon-null {
    font: 22px/1 WebComponentsIcons;
    display: inline;
}

.im-k-icon-null {
    width: 0px;
}

.im-radio-line {
    width: auto;
    padding-right: 3px;
    padding-left: 5px;
}

.im-radio-btn {
    width: auto;
}

.preview-b {
    text-align: left;
    font-size: 10px;
    color: #14587f;
    text-transform: uppercase;
}

.progress-b {
    text-align: left;
    font-size: 10px;
    color: #14587f;
    text-transform: uppercase;
}
