.tt-popover__window {
    position: fixed; 
    top: 0px; 
    left: 0px; 
    height: 100vh; 
    width: 100vw; 
    z-index: 200;
}

.tt-popover__outer {
    position: relative;
    width: 100%;
    height: 100%;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.6));
}

.tt-popover__modal {
    padding: 6px;
    position: absolute;
    width: 300px;
    background-color: var(--tt-modal--color);
    border-radius: 6px;
    pointer-events: all;
    max-height: 500px;
    overflow-y: scroll;
    z-index: 2147483647;
    top: 411.82px;
    transform: translate(-50%, -100%);
    left: 590.805px;
}

.tt-popover__arrow--up {
    position: absolute;
    top: 486.82px;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid var(--tt-modal--color);
    border-radius: 6px;
    margin: 2rem;
    left: 726.113px;
}

.tt-popover__arrow--down {
    position: absolute;
    top: 486.82px;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--tt-modal--color);
    border-radius: 6px;
    margin: 2rem;
    left: 726.113px;
}