.sp-0 {
    padding-left: 0;
    padding-right: 0;
}

.sp-1 {
    padding-left: 1px;
    padding-right: 1px;
}

.sp-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.sp-3 {
    padding-left: 3px;
    padding-right: 3px;
}

.sp-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.sp-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.sp-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.sp-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.sp-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.sp-9 {
    padding-left: 9px;
    padding-right: 9px;
}

.sp-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.sp-11 {
    padding-left: 11px;
    padding-right: 11px;
}

.sp-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.sp-13 {
    padding-left: 13px;
    padding-right: 13px;
}

.sp-14 {
    padding-left: 14px;
    padding-right: 14px;
}

.sp-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.p-0 {
    padding: 0;
}

.p-1 {
    padding: 1px;
}

.p-2 {
    padding: 2px;
}

.p-3 {
    padding: 3px;
}

.p-4 {
    padding: 4px;
}

.p-5 {
    padding: 5px;
}

.p-6 {
    padding: 6px;
}

.p-7 {
    padding: 7px;
}

.p-8 {
    padding: 8px;
}

.p-9 {
    padding: 9px;
}

.p-10 {
    padding: 10px;
}

.p-11 {
    padding: 11px;
}

.p-12 {
    padding: 12px;
}

.p-13 {
    padding: 13px;
}

.p-14 {
    padding: 14px;
}

.p-15 {
    padding-bottom: 15px;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-1 {
    padding-bottom: 1px;
}

.pb-2 {
    padding-bottom: 2px;
}

.pb-3 {
    padding-bottom: 3px;
}

.pb-4 {
    padding-bottom: 4px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-6 {
    padding-bottom: 6px;
}

.pb-7 {
    padding-bottom: 7px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pb-9 {
    padding-bottom: 9px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-11 {
    padding-bottom: 11px;
}

.pb-12 {
    padding-bottom: 12px;
}

.pb-13 {
    padding-bottom: 13px;
}

.pb-14 {
    padding-bottom: 14px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pt-0 {
    padding-top: 0;
}

.pt-1 {
    padding-top: 1px;
}

.pt-2 {
    padding-top: 2px;
}

.pt-3 {
    padding-top: 3px;
}

.pt-4 {
    padding-top: 4px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-6 {
    padding-top: 6px;
}

.pt-7 {
    padding-top: 7px;
}

.pt-8 {
    padding-top: 8px;
}

.pt-9 {
    padding-top: 9px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-11 {
    padding-top: 11px;
}

.pt-12 {
    padding-top: 12px;
}

.pt-13 {
    padding-top: 13px;
}

.pt-14 {
    padding-top: 14px;
}

.pt-15 {
    padding-top: 15px;
}

.pl-0 {
    padding-left: 0;
}

.pl-1 {
    padding-left: 1px;
}

.pl-2 {
    padding-left: 2px;
}

.pl-3 {
    padding-left: 3px;
}

.pl-4 {
    padding-left: 4px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-6 {
    padding-left: 6px;
}

.pl-7 {
    padding-left: 7px;
}

.pl-8 {
    padding-left: 8px;
}

.pl-9 {
    padding-left: 9px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-11 {
    padding-left: 11px;
}

.pl-12 {
    padding-left: 12px;
}

.pl-13 {
    padding-left: 13px;
}

.pl-14 {
    padding-left: 14px;
}

.pl-15 {
    padding-left: 15px;
}

.pr-0 {
    padding-right: 0;
}

.pr-1 {
    padding-right: 1px;
}

.pr-2 {
    padding-right: 2px;
}

.pr-3 {
    padding-right: 3px;
}

.pr-4 {
    padding-right: 4px;
}

.pr-5 {
    padding-right: 5px;
}

.pr-6 {
    padding-right: 6px;
}

.pr-7 {
    padding-right: 7px;
}

.pr-8 {
    padding-right: 8px;
}

.pr-9 {
    padding-right: 9px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-11 {
    padding-right: 11px;
}

.pr-12 {
    padding-right: 12px;
}

.pr-13 {
    padding-right: 13px;
}

.pr-14 {
    padding-right: 14px;
}

.pr-15 {
    padding-right: 15px;
}

@media (max-width: 767px) {
    .pt-xs-5 {
        padding-top: 5px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .pt-sm-top {
        padding-top: 5px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .pt-md-top {
        padding-top: 5px;
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .pt-lg-top {
        padding-top: 5px;
    }
}

@media (min-width: 1440px) and (max-width: 1919px) {
    .pt-xl-top {
        padding-top: 5px;
    }
}

@media (min-width: 1920px) {
    .pt-xxl-top {
        padding-top: 5px;
    }
}

.item-active-on {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 5px;
    border: 3px solid limegreen !important;
    border-radius: 5pt !important;
}

.item-active-on-group {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 5px;
    border: 3px solid orange !important;
    border-radius: 5pt !important;
}

.item-active-off {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 5px;
    border: 3px solid red !important;
    border-radius: 5pt !important;
}

.item-active-on-group-icon span {
    color: orange;
}

.tt-packery-item {
    margin-top: 0;
    margin-bottom: 6px;
    /*border: 1px solid #e3e3e3;
    border-radius: 3px;*/
}

.tt-packery-item-header {
    padding: 7px;
    padding-left: 3px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
}

/*.tt-packery-item-body {
    padding: 2px;
}*/

.tt-item-control {
    background: transparent;
}

.tt-btn-group > .btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-repeat: repeat-x;
    border-color: #2b669a;
    border-radius: 6px;
}

.tt-btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-right-width: 0;
}

.tt-btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-right-width: 0;
}

.tt-btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {

}

.tt-active-btn {
    background-image: linear-gradient(to bottom,#60aadd 0,#26a 100%) !important;
}

.tt-inactive-btn {
    background-image: linear-gradient(to bottom,#fff 0,#efefef 100%);
}

.modal-window-confirm {
    z-index: 1000000
}

.well-blue {
    background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
    border-color: #9acfea;
}

.grid-cell-left {
    border-right: 1px solid darkgrey;
    padding-right: 5px;
}

.grid-cell-center {
    border-right: 1px solid darkgrey;
    padding-left: 10px;
    padding-right: 5px;
}

.grid-cell-right {
    padding-left: 10px;
}

.bg-gray {
    background: gray;
    color: white;
    border-color: lightgray !important;
    border-width: 1px !important;
}

.bg-yellow {
    background: yellow;
    color: black;
    border-color: lightgray !important;
    border-width: 1px !important;
}

.bg-red {
    background: red;
    color: black;
    border-color: lightgray !important;
    border-width: 1px !important;
}

.bg-green {
    background: green;
    color: white;
    border-color: lightgray !important;
    border-width: 1px !important;
}

.modal-pst-fiftyfive {
    width: 55%
}

.modal-pst-ninety {
    width: 90%
}

.modal-pst-ninetyfive {
    width: 95%
}

.tt-btn-success-important {
    color: #fff !important;
    background-image: -webkit-linear-gradient(top,#5cb85c 0,#419641 100%) !important;
    background-image: -o-linear-gradient(top,#5cb85c 0,#419641 100%) !important;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#5cb85c),to(#419641)) !important;
    background-image: linear-gradient(to bottom,#5cb85c 0,#419641 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff419641', GradientType=0) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
    background-repeat: repeat-x !important;
    border-color: #3e8f3e !important;
}

.tt-modal-report-preview {
    height: calc(100vh - 190px);
    height: -moz-calc(100vh - 190px);
    height: -webkit-calc(100vh - 190px);
    margin-bottom: 10px;
}

.tt-hide-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    line-height: 110% !important;
}

.max-view-height {
    height: calc(100vh - 50px);
}

.font-btn {
    -webkit-appearance: none;
    padding: 0 5px 0 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .6;
}

.font-white {
    color: white;
}

.font-inherit {
    color: inherit;
}

.gt-btn {
    padding: 7px 8px;
}

.gt-icon-white {
    color: white;
    font-size: 16px;
    width: 23px;
    margin-right: 3px;
}

.font-awesome-5 {
    font-family: "Font Awesome 5 Pro" !important;
}

.font-awesome:before {
    margin: 0 !important;
}
