
.checkin-alert {
    line-height: 50px;
    /*font-size: 1000%;*/
    color: white;
}

/* FROM HERE: ONLY USED IN OEEDATACURRENT */

.insoft-alert {
    line-height: 100px;
    /*font-size: 1000%;*/
    color: white;
}

    .insoft-alert span {
        color: white;
        font-size: 68px;
        margin-top: 50px;
    }

@media (min-width: 1200px) {
    .insoft-alert {
        line-height: 450px;
    }

        .insoft-alert span {
            color: white;
            font-size: 288px;
            margin-top: 150px;
        }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .insoft-alert {
        line-height: 300px;
    }

        .insoft-alert span {
            color: white;
            font-size: 200px;
            margin-top: 150px;
        }
}
