
/* FROM HERE: ONLY USED IN RISKELEMENT */

.riskelement-green {
    background-color: green;
    color: green;
    text-align: left;
    padding: 5px;
}

.riskelement-yellow {
    background-color: yellow;
    color: yellow;
    text-align: left;
    padding: 5px;
}

.riskelement-orange {
    background-color: orange;
    color: orange;
    text-align: left;
    padding: 5px;
}

.riskelement-red {
    background-color: red;
    color: red;
    text-align: left;
    padding: 5px;
}

/* TO HERE: ONLY USED IN RISKELEMENT */
