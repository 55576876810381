
:root {
    --tt-primary-button--color: var(--tt-primary-color);
    --tt-primary-button--color--hover: var(--tt-primary-color-500);
    --tt-primary-button--text-color: var(--tt-primary-text-color);
    --tt-primary-button--text-color--hover: var(--tt-primary-text-color);
    --tt-secondary-button--color: var(--tt-secondary-color);
    --tt-secondary-button--color--hover: var(--tt-secondary-color-500);
    --tt-secondary-button--text-color: var(--tt-secondary-text-color);
    --tt-secondary-button--text-color--hover: var(--tt-secondary-text-color);
    --tt-success-button--color: var(--tt-success-color);
    --tt-success-button--color--hover: var(--tt-success-color-500);
    --tt-success-button--text-color: var(--tt-success-text-color);
    --tt-success-button--text-color--hover: var(--tt-success-text-color);
    --tt-warning-button--color: var(--tt-warning-color);
    --tt-warning-button--color--hover: var(--tt-warning-color-500);
    --tt-warning-button--text-color: var(--tt-warning-text-color);
    --tt-warning-button--text-color--hover: var(--tt-warning-text-color);
    --tt-danger-button--color: var(--tt-danger-color);
    --tt-danger-button--color--hover: var(--tt-danger-color-500);
    --tt-danger-button--text-color: var(--tt-danger-text-color);
    --tt-danger-button--text-color--hover: var(--tt-danger-text-color);
    --tt-button--border-radius: var(--tt-border-radius);
}


    :root.dark {
        --tt-primary-button--color: var(--tt-primary-color);
        --tt-primary-button--color--hover: var(--tt-primary-color-500);
        --tt-primary-button--text-color: var(--tt-primary-text-color);
        --tt-primary-button--text-color--hover: var(--tt-primary-text-color);
        --tt-secondary-button--color: var(--tt-secondary-color);
        --tt-secondary-button--color--hover: var(--tt-secondary-color-500);
        --tt-secondary-button--text-color: var(--tt-secondary-text-color);
        --tt-secondary-button--text-color--hover: var(--tt-secondary-text-color);
        --tt-success-button--color: var(--tt-success-color);
        --tt-success-button--color--hover: var(--tt-success-color-500);
        --tt-success-button--text-color: var(--tt-success-text-color);
        --tt-success-button--text-color--hover: var(--tt-success-text-color);
        --tt-warning-button--color: var(--tt-warning-color);
        --tt-warning-button--color--hover: var(--tt-warning-color-500);
        --tt-warning-button--text-color: var(--tt-warning-text-color);
        --tt-warning-button--text-color--hover: var(--tt-warning-text-color);
        --tt-danger-button--color: var(--tt-danger-color);
        --tt-danger-button--color--hover: var(--tt-danger-color-500);
        --tt-danger-button--text-color: var(--tt-danger-text-color);
        --tt-danger-button--text-color--hover: var(--tt-danger-text-color);
        --tt-button--border-radius: var(--tt-border-radius);
        --tt-button--font-weight: 600;
    }

.pagination > .active > a {
    border-color: var(--tt-primary-color);
    background: var(--tt-primary-color);
}


.btn-k {
    border-color: #c3c3c8;
    color: #515967;
    background-color: #f3f3f4;
}

    .btn-k:hover, .btn-k:focus, .btn-k:active, .btn-k.active, .btn-k:active:focus, .open > .dropdown-toggle.btn-k {
        color: #333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }

.btn-k-success {
    background-image: -webkit-linear-gradient(top,#5cb85c 0,#419641 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#5cb85c),to(#419641));
    background-image: linear-gradient(to bottom,#5cb85c 0,#419641 100%);
    background-repeat: repeat-x;
    border-color: #3e8f3e;
}

    .btn-k-success:hover, .btn-k-success:focus, .btn-k-success:active, .btn-k-success.active, .btn-k-success:active:focus, .open > .dropdown-toggle.btn-k-success {
        color: #fff;
        background-color: #00b3db;
        border-color: #285e8e;
    }

.btn-k-pressed {
    color: #fff;
    background-color: #00b3db;
    border-color: #357ebd;
}

    .btn-k-pressed:hover, .btn-k-pressed:focus, .btn-k-pressed:active, .btn-k-pressed.active, .btn-k-pressed:active:focus, .open > .dropdown-toggle.btn-k-pressed {
        color: #fff;
        background-color: #00b3db;
        border-color: #285e8e;
    }

.btn-k-recording {
    color: #fff;
    background-color: #d9534f;
    background-image: -webkit-linear-gradient(top,#d9534f 0,#c12e2a 100%);
    background-image: -o-linear-gradient(top,#d9534f 0,#c12e2a 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#d9534f),to(#c12e2a));
    background-image: linear-gradient(to bottom,#d9534f 0,#c12e2a 100%);
    background-repeat: repeat-x;
    border-color: #b92c28;
}

    .btn-k-recording:hover, .btn-k-recording:focus, .btn-k-recording:active, .btn-k-recording.active, .btn-k-recording:active:focus, .open > .dropdown-toggle.btn-k-recording {
        color: #fff;
        background-color: #d9534f;
        border-color: #b92c28;
    }

.tt-button {
    background: transparent;
    border: none;
    color: #111;
    text-shadow: none;
    box-shadow: none;
    display: block;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-radius: var(--tt-button--border-radius);
    white-space: normal;
    font-size: 1.2rem;
    font-size: var(--tt-font-size);
    height: var(--tt-height);
}



.tt-button--primary, .btn-primary {
    color: var(--tt-primary-button--text-color);
    background: var(--tt-primary-button--color);
}

    .tt-button--primary:hover,
    .btn-primary:hover {
        color: var(--tt-primary-button--text-color--hover);
        background: var(--tt-primary-button--color--hover);
        text-decoration: none;
        cursor: pointer;
    }

    .tt-button--primary:focus-visible,
    .btn-primary:focus-visible {
        box-shadow: inset 0 0 0 0.3rem #ffffff;
    }


.tt-button--secondary {
    color: var(--tt-secondary-button--text-color);
    background: var(--tt-secondary-button--color);
}

    .tt-button--secondary:hover {
        color: var(--tt-secondary-button--text-color--hover);
        background: var(--tt-secondary-button--color--hover);
        text-decoration: none;
        cursor: pointer;
    }

.tt-button--link, .tt-button--link:visited, .tt-button--link:link {
    color: var(--tt-link-color);
    font-weight: 400;
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

button.tt-button--link:hover, a.tt-button--link:hover, a.tt-button--link:active {
    color: var(--tt-link-color);
    background: rgba(var(--tt-primary-color-rgb), 0.05);
    letter-spacing: 0.3px;
    text-decoration: underline;
}

.tt-button--success,
.btn-success {
    background: var(--tt-success-button--color);
    border-radius: var(--tt-button--border-radius);
    box-shadow: none;
    text-shadow: none;
    color: var(--tt-success-button--text-color);
    border: none;
    font-weight: 600;
}

    .tt-button--success:hover,
    .btn-success:hover {
        background: var(--tt-success-button--color--hover);
        color: var(--tt-success-button--text-color--hover);
        border-radius: var(--tt-button--border-radius);
        box-shadow: none;
        text-shadow: none;
        border: none;
        font-weight: 600;
    }

.tt-button--danger,
.btn-danger {
    box-shadow: none;
    text-shadow: none;
    border-radius: var(--tt-button--border-radius);
    background: var(--tt-danger-button--color);
    color: var(--tt-danger-button--text-color);
    border: none;
}

    .tt-button--danger:hover,
    .btn-danger:hover {
        background: var(--tt-danger-button--color--hover);
        color: var(--tt-danger-button--text-color--hover);
        box-shadow: none;
        text-shadow: none;
        border: none;
        border-radius: var(--tt-button--border-radius);
    }

.tt-button--danger-ghost {
    box-shadow: none;
    text-shadow: none;
    border-radius: var(--tt-button--border-radius);
    background: transparent;
    color: var(--tt-danger-color);
    text-decoration: underline;
    border: none;
}

    .tt-button--danger-ghost:hover {
        background: rgba(var(--tt-body-text-color-rgb), 0.02);
    }

.tt-button--warning,
.btn-warning {
    background: var(--tt-warning-button--color);
    color: var(--tt-warning-button--text-color);
    text-shadow: none;
    border: none;
    border-radius: var(--tt-button--border-radius);
}

    .tt-button--warning:hover,
    .btn-warning:hover {
        background: var(--tt-warning-button--color--hover);
        color: var(--tt-warning-button--text-color--hover);
        text-shadow: none;
        border: none;
        border-radius: var(--tt-button--border-radius);
    }

button.tt-button--icon {
    letter-spacing: 0;
    color: var(--tt-primary-button--color);
}

    button.tt-button--icon:hover {
        letter-spacing: 0;
        transform: scale(1.1);
    }

.tt-button__icon--pull-right {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
}

.tt-button-multi__container {
    margin-bottom: 7px;
    display: flex;
    align-items: flex-end;
    gap: 1px;
}

.tt-button-multi__main {
    flex: 1;
}

    .tt-button-multi__main:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

.tt-button-multi__overflow-menu {
    position: absolute;
    top: 103%;
    right: 0%;
    color: black;
    /*border: 1px solid black;*/
    background-color: white;
    min-width: 120px;
    z-index: 10;
    border-radius: 6px;
    box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.5);
    overflow: clip;
}

.tt-button-multi__overflow-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tt-button-multi__overflow-item {
    padding: 8px 4px;
}

    .tt-button-multi__overflow-item button {
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
        text-align: left;
        display: flex;
        gap: 6px;
        color: black;
        white-space: nowrap;
    }

        .tt-button-multi__overflow-item button:disabled {
            outline: none;
            border: none;
            background-color: transparent;
            width: 100%;
            text-align: left;
            display: flex;
            gap: 6px;
            color: rgba(0, 0, 0, 0.3);
            white-space: nowrap;
        }

        .tt-button-multi__overflow-item:hover,
        .tt-button-multi__overflow-item button:hover {
            background-color: lightgray;
        }

.tt-button:disabled, .tt-button--disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.tt-button__badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #444;
    border-radius: 10px;
    font-size: var(--tt-font-size-xs);
}
