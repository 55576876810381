.tt-scrollbar::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.tt-scrollbar::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.6rem;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
}

.tt-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(var(--tt-scrollbar-color-rgb), 0.3);
    transition: all 0.2s ease-in-out;
}

    .tt-scrollbar::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background-color: rgba(var(--tt-scrollbar-color-rgb), 0.5);
    }

/************ TT-SIDENAV ************/
:root {
    --tt-navbar-tab--color: #e0e0e0;
}
    :root.dark {
        --tt-navbar-tab--color: #151515;
    }

.tt-sidenav {
    min-width: 18rem;
    max-width: 18rem;
    background-color: transparent;
    margin-top: -3.2rem;
    height: calc(100vh - 3.8rem);
    position: sticky;
    top: 3.8rem;
    transition: all 0.2s ease-in-out;
    pointer-events: all;
    display: flex;
    flex-direction: column;
}

.tt-navbar__logo-wrapper {
    padding: 0.2rem 0.4rem;
    padding-top: 0.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tt-navbar__logo {
    max-width: 100%;
    height: auto;
    max-height: 4.5rem;
    aspect-ratio: auto;
}

.tt-sidenav__list {
    padding: 0.4rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    margin: 0;
}

.tt-sidenav--hidden {
    width: 0;
    min-width: 0;
    opacity: 0;
    pointer-events: none;
}

.tt-sidenav--open {
    width: 18rem;
    min-width: 18rem;
    opacity: 1;
    pointer-events: all;
}

.tt-sidenav__item {
    width: 100%;
}

.tt-sidenav__link,
.tt-sidenav__link:link,
.tt-sidenav__link:visited {
    width: 100%;
    padding: 0.6rem 0.4rem;
    text-decoration: none;
    color: #c6c6c6;
    color: var(--tt-body-text-color);
    color: var(--tt-sidenav-item__text-color);
    display: flex;
    align-items: center;
    gap: 0.8rem;
    transition: all 0.01s ease-in;
    border-radius: var(--tt-border-radius);
    border: none;
    font-weight: 400;
}

    .tt-sidenav__link:hover,
    .tt-sidenav__link:active,
    .tt-sidenav__link:focus,
    .tt-sidenav__link:focus-visible {
        font-weight: 500;
        background-color: rgba(var(--tt-link-hover-color-rgb), 0.1);
        color: var(--tt-sidenav-item__text-color--hover);
        letter-spacing: 0.05rem;
    }

    .tt-sidenav__link:focus,
    .tt-sidenav__link:focus-visible {
        outline: none;
        border: none;
    }

.tt-sidenav__text {
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    transition: all 0.2s ease-in;
}

.tt-sidenav__icon {
    font-size: 1.4rem;
}

/************ TT-NAVBAR ************/

.tt-navbar {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    /* padding: 0.2rem 0.4rem; */
    padding: 0;
    width: 100%;
    min-height: 4rem;
    background-color: var(--tt-navbar-color);
    color: var(--tt-navbar-text-color);
    z-index: 200;
}

.tt-navbar--danger {
    background: var(--tt-danger-color);
}

.tt-navbar__actions--left {
    display: flex;
    align-items: center;
    max-width: calc(100% - (3.7rem * 6));
}

.tt-navbar__actions--right {
    display: flex;
    gap: 2px;
}

.tt-navbar__button,
.tt-navbar__button:link,
.tt-navbar__button:visited {
    color: var(--tt-navbar-text-color);
    /* background-color: var(--tt-primary-color-darker-1); */
    background-color: transparent;
    font-size: 1.8rem;
    padding: 0;
    position: relative;
    aspect-ratio: 1 / 1;
    width: 34px;
    height: 34px;
    border: 0.1rem solid transparent;
    transition: all 0.2s ease-in-out;
}

    .tt-navbar__button:hover {
        /* background-color: var(--tt-primary-color-darker-2); */
        font-size: 1.9rem;
    }

    .tt-navbar__button:active {
        /* background-color: var(--tt-primary-color-darker-3); */
        font-size: 1.8rem;
    }

    .tt-navbar__button:focus-visible {
        outline: none;
        border: 0.1rem solid #fff;
    }

.tt-navbar__input-wrapper {
    position: relative;
}

.tt-navbar__input {
    color: var(--tt-navbar-text-color);
    background-color: var(--tt-navbar-input-color);
    font-size: 1.2rem;
    border-radius: var(--tt-border-radius);
    /* border: 0.1rem solid rgba(255, 255, 255, 0.3); */
    border: none;
    padding: 0;
    position: relative;
    width: 100%;
    height: 4rem;
    /* padding-left: 0.8rem; */
    padding-left: 3.4rem;
    transition: all 0.2s ease-in-out;
}

.tt-navbar--danger .tt-navbar__input {
    background-color: var(--tt-danger-color-500);
}

.tt-navbar__input::placeholder {
    font-size: 1.2rem;
    font-family: inherit;
    color: rgba(var(--tt-navbar-text-color-rgb), 0.7);
}
/* 
        .tt-navbar__input--content,
        .tt-navbar__input:focus,
        .tt-navbar__input:focus-visible {
            width: 24rem;
        } */

.tt-navbar__input:focus,
.tt-navbar__input:focus-visible {
    outline: none;
    /* background-color: #010b19; */
    border: 0.1rem solid var(--tt-navbar-text-color);
    outline: 0.2rem solid var(--tt-navbar-text-color);
    outline-offset: -0.2rem;
}

@media (min-width: 768px) {

    .tt-navbar {
        min-height: 3.8rem;
    }

    .tt-navbar__input {
        width: 16rem;
        height: 3.2rem;
    }

        .tt-navbar__input::placeholder {
            font-size: 1.2rem;
            font-family: inherit;
            color: rgba(var(--tt-navbar-text-color-rgb), 0.7);
        }

        .tt-navbar__input--content,
        .tt-navbar__input:focus,
        .tt-navbar__input:focus-visible {
            width: 24rem;
        }

        .tt-navbar__input:focus,
        .tt-navbar__input:focus-visible {
            outline: none;
            /* background-color: #010b19; */
            border: 0.1rem solid var(--tt-navbar-text-color);
        }
}

@media (min-width: 1440px) {
    .tt-navbar__input,
    .tt-navbar__input--content,
    .tt-navbar__input:focus,
    .tt-navbar__input:focus-visible {
        width: 36vw;
        max-width: 72rem;
    }
}

.tt-navbar__icon {
    position: absolute;
    display: inline-block;
    color: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tt-navbar__icon--search {
    position: absolute;
    top: 50%;
    left: inherit;
    left: 0.8rem;
    transform: translateY(-50%);
    font-size: 1.8rem;
    z-index: 1;
}

.tt-navbar__heading {
    font-size: 1.6rem;
    font-weight: 500;
    overflow: hidden;
    margin: 0;
    padding: 0 0.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
}

.tt-navbar__heading--toggle {
    height: 100%;
    /* width: 100%; */
    overflow: hidden;
    padding: 0;
    border: 0;
    margin: 0;
    color: inherit;
}

.notification-container {
    position: relative;
}

.notification-counter {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: #fff;
    border-radius: 10px;
    padding: 2px 4px;
    font: 9px Verdana;
}

/************ TT-BREADCRUMBS ************/

.tt-breadcrumbs__nav {
    background-color: var(--tt-body-color);
    border-radius: 0;
    height: 16px;
    z-index: 1020;
    position: fixed;
    top: 3.8rem;
    width: 100%;
    height: 32px;
    z-index: 5;
    display: flex;
}

.tt-breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 0px;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    flex: 1;
}

.tt-breadcrumbs__pinned {
    width: fit-content;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 0px;
    flex-wrap: nowrap;
    overflow: auto;
    z-index: 1;
}

    .tt-breadcrumbs__pinned {
        /*box-shadow: 2px 6px 9px rgba(0, 0, 0, 0.3);*/
        box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.3);
    }

.tt-breadcrumbs__item {
    color: var(--tt-body-text-color);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0rem;
    border-top: 0.2rem solid transparent;
    background-color: var(--tt-navbar-tab--color);
    /*gap: 0px;*/
    padding: 0.4rem 0 0.4rem 0.8rem;
    white-space: nowrap;
}

.tt-breadcrumbs__list .tt-breadcrumbs__item:first-of-type {
    padding-right: 0.8rem;
}

.tt-breadcrumbs__item--pinned {
    background-color: var(--tt-body-color);
}

.tt-breadcrumbs__pin {
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.05s ease-in;
}

.tt-breadcrumbs__pinned-icon {
    position: relative;
    height: 100%;
    margin-right: 0.4rem;
    cursor: pointer;
}

.tt-breadcrumbs__unpin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.tt-breadcrumbs__item:hover .tt-breadcrumbs__pin {
    opacity: 1;
}

.tt-breadcrumbs__item:not(.tt-breadcrumbs__item--active) {
    border-left: 0.1rem solid rgba(var(--tt-body-text-color-rgb), 0.2);
}

.tt-breadcrumbs__item--active + .tt-breadcrumbs__item, .tt-breadcrumbs__item:first-of-type {
    border-left: none;
}

/*    .tt-breadcrumbs__item:not(:last-child)::after {
        content: ' > ';
    }
*/
.tt-breadcrumbs__item--active {
    font-weight: 600;
}

.tt-breadcrumbs__link {
    color: var(--tt-body-text-color);
}

    .tt-breadcrumbs__link:hover,
    .tt-breadcrumbs__link:active,
    .tt-breadcrumbs__link:link,
    .tt-breadcrumbs__link:visited {
        /*text-decoration: underline;*/
        color: var(--tt-body-text-color);
    }

.tt-breadcrumbs__item--active {
    border-top: 0.2rem solid var(--tt-primary-color);
    font-weight: 600;
    background-color: var(--tt-body-color);
}


.tt-breadcrumbs__nav--mobile {
    background-color: var(--tt-body-color);
    border-radius: 0;
    height: 16px;
    z-index: 1020;
    position: fixed;
    top: 3.8rem;
    width: 100%;
    height: 20px;
    z-index: 5;
}

.tt-breadcrumbs__list--mobile {
    margin: 0;
    padding: 0 0 0 5px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 6px;
    flex-wrap: wrap;
    overflow: hidden;
}

.tt-breadcrumbs__item--mobile {
    color: var(--tt-body-text-color);
    height: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
}

    .tt-breadcrumbs__item--mobile:not(:last-child)::after {
        content: ' > ';
    }

    .tt-breadcrumbs__item--mobile:last-child {
        font-weight: 600;
    }

.tt-breadcrumbs__link--mobile {
    color: var(--tt-body-text-color);
}

    .tt-breadcrumbs__link--mobile:hover,
    .tt-breadcrumbs__link--mobile:active,
    .tt-breadcrumbs__link--mobile:link,
    .tt-breadcrumbs__link--mobile:visited {
        text-decoration: underline;
        color: var(--tt-body-text-color);
    }

