@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --tt-body-color: #161616;
    --tt-body-color: #f8f9fa;
    --tt-body-text-color: #f4f4f4;
    --tt-body-text-color: #333;
    --tt-body-text-color-rgb: 3, 3, 3;
    --tt-body-font-family: "Open Sans", sans-serif;
    --tt-text-color: #000;
    --tt-navbar-color: #303030;
    --tt-navbar-color: #161616;
    --tt-navbar-input-color: #262626;
    --tt-navbar-text-color: #fff;
    --tt-navbar-text-color-rgb: 255, 255, 255;
    --tt-modal--color: #fff;
    --tt-sidenav-item__text-color: var(--tt-body-text-color);
    --tt-sidenav-item__text-color--hover: var(--tt-body-text-color);
    --tt-scrollbar-color-rgb: 255, 255, 255;
    --tt-scrollbar-color-rgb: 0, 0, 0;
    --tt-primary-color: #0d6efd;
    --tt-primary-color-rgb: 13, 110, 253;
    --tt-primary-text-color: #fff;
    --tt-primary-color-500: #0a58ca;
    --tt-secondary-color: #161616;
    --tt-secondary-text-color: #fff;
    --tt-secondary-color-rgb: 16, 16, 16;
    --tt-secondary-color-500: #323232;
    --tt-light-text-color: #fff;
    --tt-dark-text-color: #000000;
    --tt-success-color: #00693E;
    --tt-success-color-rgb: 0, 105, 62;
    --tt-success-text-color: #fff;
    --tt-success-color: #28a745;
    --tt-success-color-500: #208637;
    --tt-danger-color: #dc3545;
    --tt-danger-text-color: #fff;
    --tt-danger-color-500: #b02a37;
    --tt-danger-color-600: #842029;
    --tt-danger-color-700: #58151c;
    --tt-link-color: #6ea8fe;
    --tt-link-color: #006EE0;
    --tt-link-hover-color-rgb: 0, 3, 7;
    --tt-warning-color-200: #ffda6a;
    --tt-warning-color-300: #ffcd39;
    --tt-warning-color: #ffc107;
    --tt-warning-text-color: #111;
    --tt-warning-color-500: #cc9a06;
    --tt-focus-color: #0d6efd;
    --tt-border-radius: 1px;
    --tt-font-size-original: 12px;
    --tt-font-size: var(--tt-font-size-original);
    --tt-mobile-font-size: 2px;
    --tt-font-size-3xl: calc(var(--tt-font-size) + 18px);
    --tt-font-size-xxl: calc(var(--tt-font-size) + 12px);
    --tt-font-size-xl: calc(var(--tt-font-size) + 6px);
    --tt-font-size-lg: calc(var(--tt-font-size) + 3px);
    --tt-font-size-sm: calc(var(--tt-font-size) - 2px);
    --tt-font-size-xs: calc(var(--tt-font-size) - 3px);
    --tt-font-size-3xs: calc(var(--tt-font-size) - 8px);
    --tt-padding-top: min(calc(var(--tt-font-size) - 10px), 5px);
    --tt-padding-bottom: var(--tt-padding-top);
    --tt-padding-left: min(calc(calc(var(--tt-font-size) - 10px) + 6px), 16px);
    --tt-padding-right: var(--tt-padding-left);
    --tt-height: calc(var(--tt-font-size) + 16px);
    --tt-margin-bottom: calc((var(--tt-font-size) - mod(var(--tt-font-size), 2px)) / 2);
    --tt-margin: true;
}

@media (max-width: 768px) {
    :root {
        --tt-font-size: calc(var(--tt-font-size-original) + var(--tt-mobile-font-size));
    }
}

:root {
    --tt-boxes--color: whitesmoke;
    --tt-boxes--color-text: slategray;
    --tt-boxes--shadow: 0 0 1px lightgrey;
}

:root {
    --tt-card--font-family: "Open Sans", sans-serif;
    --tt-card--color: #262626;
    --tt-card--color: #fff;
    --tt-card--color-text: #f4f4f4;
    --tt-card--color-text: #333;
    --tt-card--color-text-rgb: 244, 244, 244;
    --tt-card--color-text-rgb: 33, 33, 33;
    --tt-card--border-radius: var(--tt-border-radius);
    --tt-card--shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
    --tt-card--border: none;
}
    /*
:root {
    --tt-input--color: #f4f4f4;
    --tt-input--color: #000;
    --tt-input--background: #393939;
    --tt-input--background: #f4f4f4;
    --tt-input--border-color: #6f6f6f;
    --tt-input--border-color: #8d8d8d;
    --tt-input--background--readonly: transparent;
    --tt-input--border-color--readonly: #3f3f3f;
    --tt-input--border-color--readonly: #e0e0e0;
    --tt-input--color--disabled: #c6c6c6;
    --tt-input--background--disabled: #f4f4f4;
    --tt-input--border-color--disabled: transparent;
    --tt-input__label--color: #c6c6c6;
    --tt-input__label--color: #555;
    --tt-input__label--text-transform: uppercase;
    --tt-input__label--font-weight: 700;
    --tt-input__group--box-shadow: none;
    --tt-input__form-control--box-shadow: none;
    --tt-input__form-control--border-left: none;
    --tt-input__form-control--border-top: none;
    --tt-input__form-control--border-right: none;
    --tt-input__form-control--border-bottom: 0.1rem solid var(--tt-input--border-color);
    --tt-input__form-control--border-left--readonly: none;
    --tt-input__form-control--border-top--readonly: none;
    --tt-input__form-control--border-right--readonly: none;
    --tt-input__form-control--border-bottom--readonly: 0.1rem solid var(--tt-input--border-color--readonly);
    --tt-input__form-control--border-left--disabled: none;
    --tt-input__form-control--border-top--disabled: none;
    --tt-input__form-control--border-right--disabled: none;
    --tt-input__form-control--border-bottom--disabled: 0.1rem solid var(--tt-input--border-color--disabled);
    --tt-input--border-top-left-radius: 0;
    --tt-input--border-top-right-radius: 0;
    --tt-input--border-bottom-left-radius: 0;
    --tt-input--border-bottom-right-radius: 0;
    --tt-input__checkbox--color: #f4f4f4;
    --tt-input__checkbox--color: #161616;
    --tt-input__checkbox--color--checked: #161616;
    --tt-input__checkbox--color--checked: #fff;
    --tt-input__checkbox--border: 0.1rem solid var(--tt-input__checkbox--color);
    --tt-input__checkbox--border-radius: 2px;
}
*/
    /* DARKMODE VARIABLES*/

    :root.dark {
        --tt-body-color: #161616;
        --tt-body-text-color: #f4f4f4;
        --tt-body-text-color-rgb: 244, 244, 244;
        --tt-body-font-family: "Open Sans", sans-serif;
        --tt-text-color: #fff;
        --tt-navbar-color: #202020;
        --tt-navbar-input-color: #292929;
        --tt-navbar-text-color: #fff;
        --tt-navbar-text-color-rgb: 255, 255, 255;
        --tt-sidenav-item__text-color: #efefef;
        --tt-sidenav-item__text-color--hover: #fff;
        --tt-scrollbar-color-rgb: 255, 255, 255;
        --tt-primary-color: #0d6efd;
        --tt-primary-color-rgb: 13, 110, 253;
        --tt-primary-text-color: #fff;
        --tt-primary-color-500: #0a58ca;
        --tt-secondary-color: #6f6f6f;
        --tt-secondary-text-color: #fff;
        --tt-secondary-color-rgb: 111, 111, 111;
        --tt-secondary-color-500: #595959;
        --tt-light-text-color: #fff;
        --tt-dark-text-color: #000000;
        --tt-success-color: #00693E;
        --tt-success-text-color: #fff;
        --tt-success-color: #28a745;
        --tt-success-color-500: #208637;
        --tt-danger-color: #dc3545;
        --tt-danger-text-color: #fff;
        --tt-danger-color-500: #b02a37;
        --tt-danger-color-600: #842029;
        --tt-danger-color-700: #58151c;
        --tt-link-color: #6ea8fe;
        --tt-link-hover-color-rgb: 100, 100, 100;
        --tt-warning-color-200: #ffda6a;
        --tt-warning-color-300: #ffcd39;
        --tt-warning-color: #ffc107;
        --tt-warning-text-color: #111;
        --tt-warning-color-500: #cc9a06;
        --tt-focus-color: #fff;
        --tt-border-radius: 1px;
        --fa-secondary-opacity: 1;
        --fa-primary-opacity: 0.4;
    }


    :root.dark {
        --tt-body-color: #0e0e0e;
        --tt-body-text-color: #f4f4f4;
        --tt-body-text-color-rgb: 244, 244, 244;
        --tt-body-font-family: "Open Sans", sans-serif;
        --tt-navbar-color: #151515;
        --tt-navbar-input-color: #242424;
        --tt-navbar-text-color: #fff;
        --tt-navbar-text-color-rgb: 255, 255, 255;
        --tt-sidenav-item__text-color: #d0d0d0;
        --tt-sidenav-item__text-color--hover: #fff;
        --tt-modal--color: #333;
        --tt-scrollbar-color-rgb: 255, 255, 255;
        --tt-primary-color: #0d6efd;
        --tt-primary-color-rgb: 13, 110, 253;
        --tt-primary-text-color: #fff;
        --tt-primary-color-500: #0a58ca;
        --tt-secondary-color: #6f6f6f;
        --tt-secondary-text-color: #fff;
        --tt-secondary-color-rgb: 111, 111, 111;
        --tt-secondary-color-500: #595959;
        --tt-light-text-color: #fff;
        --tt-dark-text-color: #000000;
        --tt-success-color: #00693E;
        --tt-success-text-color: #fff;
        --tt-success-color: #28a745;
        --tt-success-color-500: #208637;
        --tt-danger-color: #dc3545;
        --tt-danger-text-color: #fff;
        --tt-danger-color-500: #b02a37;
        --tt-danger-color-600: #842029;
        --tt-danger-color-700: #58151c;
        --tt-link-color: #6ea8fe;
        --tt-link-hover-color-rgb: 100, 100, 100;
        --tt-warning-color-200: #ffda6a;
        --tt-warning-color-300: #ffcd39;
        --tt-warning-color: #ffc107;
        --tt-warning-text-color: #111;
        --tt-warning-color-500: #cc9a06;
        --tt-focus-color: #fff;
        --tt-border-radius: 1px;
        --fa-secondary-opacity: 1;
        --fa-primary-opacity: 0.4;
    }


    :root.dark {
        --tt-boxes--color: var(--tt-body-color);
        --tt-boxes--color-text: var(--tt-body-text-color);
        --tt-boxes--shadow: 0 0 1px lightgrey;
    }

    :root.dark {
        --tt-card--font-family: "Open Sans", sans-serif;
        --tt-card--color: #191919;
        --tt-card--color-text: #f4f4f4;
        --tt-card--color-text-rgb: 244, 244, 244;
        --tt-card--border-radius: var(--tt-border-radius);
        --tt-card--shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
        --tt-card--border: none;
    }
    /*
:root.dark {
    --tt-input--color: #f4f4f4;
    --tt-input--background: #393939;
    --tt-input--border-color: #6f6f6f;
    --tt-input--background--readonly: transparent;
    --tt-input--border-color--readonly: #3f3f3f;
    --tt-input--color--disabled: #525252;
    --tt-input--background--disabled: #262626;
    --tt-input--border-color--disabled: transparent;
    --tt-input__label--color: #c6c6c6;
    --tt-input__label--text-transform: uppercase;
    --tt-input__label--font-weight: 700;
    --tt-input__group--box-shadow: none;
    --tt-input__form-control--box-shadow: none;
    --tt-input__form-control--border-left: none;
    --tt-input__form-control--border-top: none;
    --tt-input__form-control--border-right: none;
    --tt-input__form-control--border-bottom: 0.1rem solid var(--tt-input--border-color);
    --tt-input__form-control--border-left--readonly: none;
    --tt-input__form-control--border-top--readonly: none;
    --tt-input__form-control--border-right--readonly: none;
    --tt-input__form-control--border-bottom--readonly: 0.1rem solid var(--tt-input--border-color--readonly);
    --tt-input__form-control--border-left--disabled: none;
    --tt-input__form-control--border-top--disabled: none;
    --tt-input__form-control--border-right--disabled: none;
    --tt-input__form-control--border-bottom--disabled: 0.1rem solid var(--tt-input--border-color--disabled);
    --tt-input--border-top-left-radius: 0;
    --tt-input--border-top-right-radius: 0;
    --tt-input--border-bottom-left-radius: 0;
    --tt-input--border-bottom-right-radius: 0;
    --tt-input__checkbox--color: #f4f4f4;
    --tt-input__checkbox--color--checked: #161616;
    --tt-input__checkbox--border: 0.1rem solid var(--tt-input__checkbox--color);
    --tt-input__checkbox--border-radius: 2px;
}
*/
    :root.dark {
        --tt-tabs--border-width: 0.25rem;
        --tt-tabs--color--hover: rgba(var(--tt-primary-color-rgb), 0.05);
        --tt-tabs--border-color: rgba(var(--tt-body-text-color-rgb), 0.1);
        --tt-tabs--border-color--hover: rgba(var(--tt-body-text-color-rgb), 0.3);
        --tt-tabs--border-color--active: var(--tt-primary-color);
    }


*:focus-visible {
    outline: 0.2rem solid var(--tt-focus-color);
    outline-offset: -0.1rem;
}

body {
    font-family: var(--tt-body-font-family);
    background-color: var(--tt-body-color);
    color: var(--tt-body-text-color);
}


th, .th {
    font-size: 0.9em; /* equal to 10px */
}

td, .td {
    font-size: 0.9em; /* equal to 10px */
}

input, .input {
    font-size: 1.0em; /* equal to 10px */
    width: 100%;
}

input, .form-inline {
    width: 100%;
}

label, .label {
    font-size: 1.6em; /* equal to 14px */
}

legend {
    width: auto;
    border-bottom: 0px;
}

    legend a {
        color: inherit;
    }

.tt-warning-background {
    color: var(--tt-warning-text-color) !important;
    background-color: var(--tt-warning-color);
}

.tt-danger-background {
    color: var(--tt-danger-text-color) !important;
    background-color: var(--tt-danger-color);
}

.tt-primary-background {
    color: var(--tt-primary-text-color) !important;
    background-color: var(--tt-primary-color);
}

.tt-success-background {
    color: var(--tt-success-text-color) !important;
    background-color: var(--tt-success-color);
}

.tt-secondary-background {
    color: var(--tt-secondary-text-color) !important;
    background-color: var(--tt-secondary-color);
}
