.carousel-control.left, .carousel-control.right {
    background-image: none;
    background: transparent;
}

.carousel {
    background: transparent;
}

.carousel-cell {
    max-width: 90%;
    width: 90%;
    min-width: 90%;
    height: 800px;
    margin-right: 10px;
}

.nav-carousel {
    height: 20px;
    width: 25%;
}

    .nav-carousel .carousel-cell {
        height: 20px;
        width: 20px;
        margin-right: 2px;
        border-radius: 0px;
    }

        .nav-carousel .carousel-cell:before {
            font-size: 12px;
            line-height: 20px;
        }

        .nav-carousel .carousel-cell.is-nav-selected {
            background: #ED2;
        }

.carousel iframe {
    border: none;
}
