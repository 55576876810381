
.drop-box {
    background: rgba(var(--tt-card--color-text-rgb), 0.03);
    border: 5px dashed rgba(var(--tt-card--color-text-rgb), 0.2);
    height: 65px;
    text-align: center;
    padding-top: 12px;
}

.dragover {
    border: 5px dashed var(--tt-focus-color);
}
