@use "@angular/material" as mat;
@use "sass:map";

@include mat.core();

$touchtime-primary-palette: (
    50: #cfe2ff,
    100: #cfe2ff,
    200: #9ec5fe,
    300: #6ea8fe,
    400: #3d8bfd,
    500: #0d6efd,
    600: #0a58ca,
    700: #084298,
    800: #052c65,
    900: #031633,
    contrast: (
        50: black,
        100: black,
        200: black,
        300: black,
        400: black,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

$touchtime-secondary-palette: (
    50: #afafaf,
    100: #8f8f8f,
    200: #676767,
    300: #545454,
    400: #363636,
    500: #161616,
    600: #161616,
    700: #161616,
    800: #161616,
    900: #161616,
    contrast: (
        50: black,
        100: black,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

$touchtime-danger-palette: (
    50: #f8d7da,
    100: #f8d7da,
    200: #f1aeb5,
    300: #ea868f,
    400: #e35d6a,
    500: #dc3545,
    600: #b02a37,
    700: #842029,
    800: #58151c,
    900: #2c0b0e,
    contrast: (
        50: black,
        100: black,
        200: black,
        300: black,
        400: black,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

$tt-primary: mat.define-palette($touchtime-primary-palette);
$tt-accent: mat.define-palette($touchtime-secondary-palette);
$tt-warn: mat.define-palette($touchtime-danger-palette);

// $tt-typography-level: mat.define-typography-level(
//     $font-size: 1em,
//     $letter-spacing: normal,
// );

$tt-typography: mat.define-typography-config(
    $font-family: "Open Sans",
    /*$headline-1: $tt-typography-level,
    $headline-2: $tt-typography-level,
    $headline-3: $tt-typography-level,
    $headline-4: $tt-typography-level,
    $headline-5: $tt-typography-level,
    $headline-6: $tt-typography-level,
    $subtitle-1: $tt-typography-level,
    $subtitle-2: $tt-typography-level,
    $body-2: $tt-typography-level,
    $body-1: $tt-typography-level,
    $button: $tt-typography-level,
    */
);

$tt-theme: mat.define-light-theme(
    (
        color: (
            primary: $tt-primary,
            accent: $tt-accent,
            warn: $tt-warn,
        ),
        typography: $tt-typography,
        density: 0,
    )
);

$tt-theme-dark: mat.define-dark-theme(
    (
        color: (
            primary: $tt-primary,
            accent: $tt-accent,
            warn: $tt-warn,
            background: (
                dialog: #000,
            ),
        ),
        // typography: $tt-typography,
        // background: (
        //     dialog: #000,
        // ),
        // density: 0,,
    )
);
// $custom-dark-theme: map.set($custom-dark-theme, 'color', 'background', $custom-dark-theme-background);

// @include mat.all-component-themes($tt-theme);
.light {
    @include mat.all-component-themes($tt-theme);
    --mdc-dialog-container-shape: 1px;
    // @include mat.angular-material-theme($tt-theme);
}

.dark {
    @include mat.all-component-colors($tt-theme-dark);
    // @include mat.angular-material-theme($tt-theme-dark);
    --mdc-dialog-container-shape: 1px;
    & .mat-mdc-dialog-container {
        --mdc-dialog-container-color: var(--tt-modal--color);
    }
}
