:root {
    --im-table--border-color: #ddd;
    --im-table--header--color: #0dcaf0;
    --im-table--row--color--hover: rgba(0, 0, 0, 0.04);
}

:root.dark {
    --im-table--border-color: #444;
    --im-table--row--color--hover: rgba(0, 0, 0, 0.5);
}

.im-body-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.im-table-container {
    overflow: auto;
}

.im-table-thr {
    background-color: #dedee0;
    background-color: #95ccee;
    background-color: #17a2b8; /*bootstrap-info-color v4*/
    background-color: #0dcaf0; /*bootstrap-info-color v5*/
    background-color: var(--im-table--header--color);
    color: black;
}

.im-table-tr {
    background-color: #f5f5f7;
    background-color: #f8f9fa;
    background-color: transparent;
}

.im-table-tr-active {
    background-color: #f5f5f7;
    background-color: #f8f9fa;
    -webkit-box-shadow: inset 0px 0.5px 3px 3px rgb(240 195 35 / 67%);
    box-shadow: inset 0px 0.5px 3px 3px rgb(240 195 35 / 67%);
}

.im-table-tr:hover,
.im-table-tr-active:hover {
    /*background-color: #f5f5f7;*/
    background: var(--im-table--row--color--hover);
    cursor: pointer;
}

.im-table-td,
.im-table-th {
    text-align: left;
    padding: 8px;
    border: 1px solid var(--im-table--border-color);
    border-width: 2px 1px;
}

.im-td,
.im-th {
    border: 1px solid var(--im-table--border-color);
    text-align: left;
    padding: 8px;
}

.im-td-cb {
    border: 1px solid var(--im-table--border-color);
    text-align: left;
    padding: 6px 0px;
}

.im-td-fav {
    border: 1px solid var(--im-table--border-color);
    text-align: left;
    padding: 4px;
}

.im-tr-active,
.im-tr-fav {
    -webkit-box-shadow: inset 0px 0.5px 3px 3px rgb(240 195 35 / 67%);
    box-shadow: inset 0px 0.5px 3px 3px rgb(240 195 35 / 67%);
}

.im-tr:nth-child(even) {
    background-color: #dedee0;
}

.im-table-badge {
    color: #ffffff;
    background-color: #64aae6;
}

.im-table-descriptive-cell {
    display: normal;
}

@media (max-width: 500px) {
    .im-table-descriptive-cell {
        display: none;
    }
}

.gold {
    background-color: gold !important;
}

.verticalText {
    display: inline-block;
    white-space: nowrap;
    transform: translate(0, 100%) rotate(-75deg);
    transform-origin: 0 0;
    position: absolute;
    bottom: 5px;
}

.im-no-pad {
    padding-right: 0px;
    padding-left: 0px;
}

.im-no-pad-r {
    padding-right: 0px;
}

.im-no-pad-l {
    padding-left: 0px;
}

.im-sort-pad {
    padding: 5px 4px;
}

.im-no-mar {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.im-group-mar {
    margin-bottom: 15px;
}

.inline-editable {
    background-color: red;
}

.im-l-c-l {
    text-align: left;
    padding-right: 5px;
    overflow: hidden;
}

.im-l-c-c {
    text-align: center;
    padding-right: 5px;
    overflow: hidden;
}

.im-l-c-r {
    text-align: right;
    padding-right: 5px;
    overflow: hidden;
}

.imrl-0i {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.imrl-0 {
    margin-right: 0;
    margin-left: 0;
}

.sm-0 {
    margin: 0 !important;
}

.imp-12 {
    padding-top: 12px;
}

@media (max-width: 500px) {
    .im-nobile {
        display: none;
    }
}

@media (min-width: 500px) {
    .im-mobile {
        display: none;
    }
}

.height-full {
    height: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column > * {
    flex-shrink: 0 !important;
}

.flex-1 {
    flex: 1;
}

.form-field-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;

    & > * {
        flex: 1;
        min-width: 215px;
    }
}
