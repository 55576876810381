:root {
    --tt-overflow--color: #f4f4f4;
    --tt-overflow--text-color: #333;
}

:root.dark {
    --tt-overflow--color: #393939;
    --tt-overflow--text-color: #f4f4f4;
}

.tt-overflow-menu__button--open {
    background: var(--tt-overflow--color);
}

.tt-overflow-menu__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tt-overflow-menu__button {
    position: relative;
    transition: none;
    display: flex;
    color: var(--tt-card--color-text);
    align-items: center;
    justify-content: center;
    height: 3.2rem;
    width: 3.2rem;
}

.tt-overflow__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: var(--tt-overflow--text-color);
    background: var(--tt-overflow--color);
    top: 100%;
    right: 0;
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.3);
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 15rem;
    z-index: 10;
}

.tt-overflow-item {
    width: 100%;
}

.tt-overflow-item {
    width: 100%;
}

.tt-overflow-item__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    outline: 0;
    border: 0;
    background: transparent;
    padding: 0.6rem 1.6rem;
    transition: all 0.15s ease-in;
    text-align: left;
    gap: 0.8rem;
}

    .tt-overflow-item__button:hover {
        background: #e5e5e5;
    }

.tt-overflow-item__button--danger:hover {
    background: var(--tt-danger-color);
    color: white;
}

.tt-overflow-item__seperator {
    width: 100%;
    height: 0.1rem;
    background: #e0e0e0;
    margin: 0.2rem 0;
}
