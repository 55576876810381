.well {
    background: var(--tt-card--color);
    margin-bottom: 0px;
    border: var(--tt-card--border);
    box-shadow: var(--tt-card--shadow);
    border-radius: var(--tt-card--border-radius);
}

.well-xs {
    padding: 5px;
    border-radius: 2px;
}

.well-sq {
    padding: 0 0 5px;
    border-radius: 0;
    margin: 0;
}

.well-sm {
    padding: 9px;
}
