h3, .tt-card__heading {
    font-size: 18px;
    font-family: var(--tt-card--font-family);
}

.input-text-center {
    text-align: center;
    font-size: 30px;
}

.input-text-right {
    text-align: right;
    font-size: 30px;
}

.select-text {
    margin-bottom: 0px;
    font-size: 30px;
}

.spin-text {
    color: white;
    font-size: 18px;
}

.radio-label {
    text-align: left;
    font-size: 14px;
    color: #555;
    text-transform: uppercase;
}

.legend-label {
    margin: 0px;
    text-align: left;
    color: #14587F;
    text-transform: uppercase;
}

.name-label {
    margin: 0px;
    text-align: left;
    color: var(--tt-input__label--color);
    text-transform: uppercase;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.name-label-required {
    margin: 0px;
    padding-left: 0px;
    text-align: left;
    color: crimson;
}

.name-sublabel {
    margin: 0px;
    padding-left: 1px;
    text-align: left;
    color: dimgray;
}

.tt-header {
    background-color: transparent;
    color: var(--tt-body-text-color);
    text-align: left;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    font-weight: 300;
}

.checklist-h1 {
    background-color: dimgrey;
    color: white;
    text-align: left;
    padding: 5px;
}

.checklist-h1 {
    background-color: dimgrey;
    color: white;
    text-align: left;
    padding: 5px;
}

.checklist-h2 {
    background-color: var(--tt-danger-color);
    color: white;
    text-align: left;
    padding: 5px;
}

.checklist-h1e {
    background-color: dimgrey;
    color: white;
    text-align: left;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.checklist-h2e {
    background-color: var(--tt-danger-color);
    color: white;
    text-align: left;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
