/* FROM HERE: ONLY USED IN PICTURES */
.gallery {
    padding: 0;
    width: auto;
    display: inline-block;
    text-align: center;
}

    .gallery li {
        display: inline-block;
        margin: 0.5em 0.5em 1em 0;
        list-style-type: none;
        text-align: center;
    }

    .gallery img {
        width: 75px;
        height: 75px;
    }
/* TO HERE: ONLY USED IN PICTURES */

/* FROM HERE: ONLY USED IN PICTURES */

.close-img-container {
    position: relative;
    top: -24px;
    right: 24px;
}

.close-img {
    width: 15px !important;
    height: 15px !important;
    -webkit-transition: -webkit-transform .25s, opacity .25s;
    -moz-transition: -moz-transform .25s, opacity .25s;
    -ms-transition: transform .25s, opacity .25s;
    -o-transition: transform .25s, opacity .25s;
    transition: transform .25s, opacity .25s;
    -ms-opacity: .25;
    opacity: .25;
}

    .close-img:hover {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
        -ms-opacity: 1;
        opacity: 1;
    }
/* TO HERE: ONLY USED IN PICTURES */
