
.tt-image__placeholder {
    aspect-ratio: 2 / 3;
    min-height: 150px;
    width: 100%;
    height: 100%;
    max-height: 220px;
    max-width: 100%;
    background-color: #ddd;
    border: 3px solid #aaa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: clip;
}

.tt-image__img-container {
    position: relative;
    height: fit-content;
    width: fit-content;
    transition: all 0.1s ease-in;
}

    .tt-image__img-container .tt-image__btns {
        opacity: 0.5;
        transition: all 0.1s ease-in;
    }

    .tt-image__img-container:hover .tt-image__btns {
        opacity: 1;
    }

.tt-image__img {
    max-height: 220px;
    max-width: 100%;
}

.tt-image__btns {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
    z-index: 1069;
}

    .tt-image__btns:focus-within {
        opacity: 1;
    }

.tt-image__btn {
    position: relative;
    transition: all 0.1s ease-in;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border: none;
    aspect-ratio: 1 / 1;
    padding: 6px;
    border-radius: 100%;
}

    .tt-image__btn:hover,
    .tt-image__btn:focus-visible {
        background-color: rgba(0, 0, 0, 1);
    }

.tt-image__btn-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.tt-image__placeholder-icon {
    font-size: 36px;
}

@media (max-width: 758px) {
    .tt-image__img-container .tt-image__btns {
        opacity: 1;
    }
}

.tt-image__img-container:focus-visible .tt-image__btns {
    opacity: 1;
}

.tt-image-popover {
    z-index: 1068;
    position: fixed;
    width: min(400px, calc(100vw - 40px));
}


.img-thumbnail {
    padding: 1px;
}
