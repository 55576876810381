:root {
    --tt-grid--header--color: #fff;
    --tt-grid--table--color: #fff;
    --tt-grid--header--border-color: #ceced2;
}

:root.dark {
    --tt-grid--header--color: #393939;
    --tt-grid--header--border-color: #555;
    --tt-grid--table--color: #262626;
}

.k-grid {
    background-color: var(--tt-grid--table--color);
}
.k-grid td.k-state-focused {
    box-shadow: inset 0 0 3px 1px #e38d13;
}

.k-grid .nowrap {
    white-space: var(--im-k-nowrap, nowrap);
}

.k-grid-toolbar {
    padding: 0px;
}

.k-header,
th.k-header {
    background-color: var(--tt-grid--header--color);
    border-color: var(--tt-grid--header--border-color);
    font-weight: 700;
}

.k-grid-header {
    background-color: var(--tt-grid--header--color);
}

.k-grid tr:hover {
    background: rgba(var(--tt-primary-color-rgb), 0.5);
}

.im-grid-radio {
    color: black;
    font-size: medium;
    width: 0%;
    margin-right: 8px;
    margin-left: 8px;
}

.im-grid-checkbox {
    border-radius: 2px;
    margin: 0 !important;
    padding: 0;
    width: 18px;
    height: 18px;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
}

.im-grid-checkbox::before {
    width: 12px;
    height: 12px;
    font-size: 12px;
    line-height: 1;
    -webkit-transform: scale(0) translate(-50%, -50%);
    -ms-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    font-family: WebComponentsIcons, monospace;
}

.im-grid-checkbox:hover {
    cursor: pointer;
}

.im-grid-checkbox:checked::before {
    border-radius: 2px;
    -webkit-transform: scale(1) translate(-50%, -50%);
    -ms-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
}

.im-grid-checkbox:indeterminate::before {
    content: "";
    background-color: currentColor;
    -webkit-transform: scale(1) translate(-50%, -50%);
    -ms-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
    width: 8px;
    height: 8px;
}

.im-grid-checkbox:disabled {
    outline: none;
    cursor: default;
    opacity: 0.6;
    -webkit-filter: grayscale(0.1);
    filter: grayscale(0.1);
    pointer-events: none;
    box-shadow: none;
}

.im-grid-checkbox::-ms-check {
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background-color: inherit;
}

.im-grid-checkbox {
    border-color: rgba(0, 0, 0, 0.08);
    color: transparent;
    background-color: #ffffff;
}

.im-grid-checkbox:hover {
    border-color: rgba(0, 0, 0, 0.08);
}

.im-grid-checkbox:focus {
    border-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.im-grid-checkbox:indeterminate {
    border-color: rgba(0, 0, 0, 0.08);
    color: #ff6358;
    background-color: #ffffff;
}

.im-grid-checkbox:checked {
    border-color: #ff6358;
    color: #ffffff;
    background-color: #ff6358;
}

.im-grid-checkbox:checked:focus {
    border-color: #ff6358;
    box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.im-grid-checkbox.ng-invalid.ng-touched,
.im-grid-checkbox.ng-invalid.ng-dirty {
    border-color: rgba(243, 23, 0, 0.5);
}

.im-grid-toolbar-btn-i {
    padding: 6px 8px;
    border-radius: 0px;
    border-width: 0px;
    margin: 0px;
}

.im-grid-toolbar-tgl-i {
    padding: 6px 8px;
    border-radius: 0px;
    border-width: 0px;
    margin: 0px;
}

.im-grid-btn-xs,
.im-grid-tgl-xs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 9px;
    gap: 0.5rem;
}

.tt-grid-btn-xs {
    flex-wrap: nowrap;
    align-items: center;
    font-size: 10px;
    display: inline-flex;
    gap: 0.5rem;
    padding: 6px 8px;
    border-radius: 0px;
    border-width: 0px;
    vertical-align: middle;
}

.tt-grid-btn-xs:hover {
    background-color: #265a88;
    background-position: 0 -15px;
    color: #fff;
    border-color: #204d74;
    text-decoration: none;
}

.tt-grid-btn-xs:active {
    color: #fff;
    background-color: #265a88;
    border-color: #245580;
    background-image: none;
}

.tt-grid-btn-xs:active:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
}

.im-grid-btn-xs-r,
.im-grid-tgl-xs-r,
.im-grid-btn-xs-l,
.im-grid-tgl-xs-l {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px;
    margin-left: 2px;
    margin-right: 6px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin: 1px 6px 1px 0px;
    gap: 0.5rem;
}

.im-grid-btn-xs-r,
.im-grid-tgl-xs-r {
    float: right;
}

.im-grid-btn-xs-l,
.im-grid-tgl-xs-l {
    float: left;
}

.im-grid-btn-xxs-r,
.im-grid-tgl-xxs-r {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    float: right;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 9px;
    gap: 0.5rem;
}

.im-grid-col-btn-xs {
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px;
    margin-left: 4px;
    width: 10px;
}

.im-grid-col-btn-xxs {
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    border-radius: 3px;
    margin-left: 4px;
    width: 10px;
}

.im-grid-col-btn-xs-r {
    float: right;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px;
    margin-left: 4px;
    width: 10px;
}

.im-grid-col-btn-xxs-r {
    float: right;
    padding: 4px 8px;
    font-size: 10px;
    line-height: 1;
    border-radius: 3px;
    margin-left: 4px;
    width: 10px;
}

.im-grid-col-btn-hidden {
    display: none;
}

.tt-cell-goto {
    color: var(--tt-link-color);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
}

.k-state-selected .tt-cell-goto {
    color: white;
}

.k-grid td span {
    color: inherit;
}

.im-grid-cols-modal-column-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
}

.im-grid-cols-modal-column-btns {
    padding: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.im-grid-cols-modal-column-btn {
    padding: 6px 9px;
    font-size: 18px;
    line-height: 1;
    border-radius: 3px;
    margin: 12px;
}

.im-grid-cols-modal-column-btn-i {
    color: white;
    width: 18px;
}

.im-grid-cols-modal-row-selected {
    background-color: lightskyblue !important;
}

.im-grid-cols-modal-checkbox {
    height: 1.5em;
    vertical-align: text-bottom;
    margin: 0 !important;
}

.img-grid-thumbnail {
    max-height: 30px;
}

.img-grid-thumbnail-hidden {
    /*display: none;*/
    visibility: hidden;
}

.tt-grid__cell {
    min-width: 40px !important;
    width: 100%;
}

.tt-button.im-grid-col-btn-hidden {
    display: none;
}

.fixed-header,
.fixed-header-extended {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.fixed-header-extended {
    width: auto;
}

.tt-button.im-grid-col-btn-xs {
    height: auto;
}
