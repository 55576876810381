
i.glyphicon-question-sign {
    font-size: 1.0em;
    color: white;
}

i.glyphicon-remove-sign {
    font-size: 20px;
    color: #B8B8B8;
}

    i.glyphicon-remove-sign:hover {
        font-size: 20px;
        color: red;
    }

i.glyphicon-save {
    font-size: 1.0em;
    color: white;
}

i.glyphicon-lock {
    font-size: 1.0em;
    color: black;
}

i.glyphicon-pencil {
    font-size: 1.0em;
    color: white;
}

i.glyphicon-trash {
    font-size: 1.0em;
    color: white;
}

i.glyphicon-arrow-right {
    font-size: 1.0em;
    color: white;
}

.glyphicon {
    top: 0;
}

.help_question {
    color: #286090;
    font-size: 20px;
}

/*
  special blankt glyphicon
*/
.glyphicon-none:before {
    content: "\2212";
    color: transparent !important;
    visibility: hidden;
}

.insoft-lv {
    text-align: left;
    padding: 0.5em;
    margin-bottom: 5px;
    color: #333333;
    background: #ffffff;
    border-color: #ffffff #ffffff #CCCCCC;
    border-radius: 0;
}

span.error {
    font-size: 1.6em;
    padding: auto;
}

span.glyphicon-exclamation-sign {
    font-size: 1.6em;
    color: black;
    padding: auto;
}

span.glyphicon-lock {
    font-size: 1.0em;
    color: black;
}


.fa.k-sprite,
.fa.k-sprite::before,
.fab.k-sprite,
.fab.k-sprite::before,
.fad.k-sprite,
.fad.k-sprite::before,
.fal.k-sprite,
.fal.k-sprite::before,
.far.k-sprite,
.far.k-sprite::before,
.fas.k-sprite,
.fas.k-sprite::before {
    /*font-size: 12px;*/
    font-size: inherit;
    /*line-height: 12px;*/
    line-height: inherit;
    vertical-align: middle;
    height: inherit;
    width: inherit;
}
