:root {
    --im-favourites--delete--color: #dc3545;
}

    :root.dark {
        --im-favourites--delete--color: #EA868F;
    }

.im-favorites-table {
    border-color: var(--tt-grid--header--border-color);
}

.im-favorites-container {
    /*overflow: auto;*/
    position: relative;
    cursor: pointer;
}

.im-favorites-overlay, .im-favorites-new {
    position: absolute;
    bottom: 0;
    left: 100%;
    right: 0;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
}

.im-favorites-active, .im-favorites-active-2 {
    position: absolute;
    bottom: 0;
    left: 100%;
    right: 0;
    overflow: hidden;
    width: 0;
    height: 100%;
}

.im-favorites-current {
    position: absolute;
    bottom: 0;
    left: calc(100% - 72px);
    right: 0;
    /*overflow: hidden;*/
    width: 36px;
    height: 100%;
}

.im-favorites-icon, .im-favorites-icon-active, .im-modal-filter-icon {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.im-favorites-icon-active {
    color: #f0ad4e;
}

.im-modal-filter-icon {
    color: var(--tt-body-text-color);
    opacity: 0.3;
}

.im-favorites-icon-active .im-tooltiptext {
    visibility: hidden;
    color: var(--tt-body-text-color);
    font-size: 9px;
    position: absolute;
    z-index: 1;
    bottom: 25%;
    left: -10em;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 100;
}

.im-favorites-container:hover .im-favorites-overlay, .im-favorites-new, .im-favorites-active {
    width: 36px;
    left: calc(100% - 36px);
}

.im-favorites-container:hover .im-favorites-active, .im-favorites-active-2 {
    width: 36px;
    left: calc(100% - 72px);
}

.im-favorites-container:hover .im-favorites-active-2 {
    width: 36px;
    left: calc(100% - 108px);
}

.im-favorites-container:hover .im-favorites-text {
    width: calc(100% - 36px);
}

.im-favorites-container:hover .im-favorites-newtab, .im-favorites-newtab {
    right: 54px;
}

.im-favorites-newtab {
    position: absolute;
    right: 54px;
    top: 2px;
    padding: 0.7em;
    border: none;
    background: transparent;
    color: #b8b8b8;
}

.im-favorites-chevron {
    position: absolute;
    right: 34px;
    top: 1px;
    padding: 0.7em;
    border: none;
    background: 0 0;
    color: #b8b8b8;
}

.im-newtab .im-tooltiptext, .im-favorites-newtab .im-tooltiptext {
    visibility: hidden;
    color: var(--tt-body-text-color);
    font-size: 9px;
    position: absolute;
    z-index: 1;
    bottom: 33%;
    left: -3em;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 100;
}

.im-favorites--delete-warning {
    color: var(--im-favourites--delete--color);
}

.im-newtab:hover, .im-favorites-newtab:hover {
    color: #1984c8;
}

    .im-newtab:hover .im-tooltiptext, .im-favorites-newtab:hover .im-tooltiptext, .im-favorites-icon-active:hover .im-tooltiptext {
        visibility: visible;
    }

