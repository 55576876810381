@keyframes notify-bounce {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -6px);
        -ms-transform: translate(0px, -6px);
        transform: translate(0px, -6px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.notify-bounce {
    animation-name: notify-bounce;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 0.3s;
}

.glyphicon-refresh-animate {
    animation: spin .7s infinite linear;
    -webkit-animation: spin .7s infinite linear;
}

@keyframes spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.fadeinout-animate {
    -webkit-animation: fadeinout 2s infinite linear forwards;
    animation: fadeinout 2s infinite linear forwards;
    opacity: 0;
}

.fadeout-animate {
    -webkit-animation: fadeout 2s linear forwards;
    animation: fadeout 2s linear forwards;
    opacity: 0;
}

.fadeout-animate {
    -webkit-animation: fadeout 2s linear forwards;
    animation: fadeout 2s linear forwards;
    opacity: 0;
}

@keyframes fadeinout {
    25% {
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
}

.fa-spin-custom, .glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    -ms-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
