:root {
    --w-email__attachment--color: var(--tt-card--color);
    --w-email__attachment--text-color: var(--tt-card--color-text);
    --w-email__attachment--color--hover: rgba(0, 0, 0, 0.1);
    --w-email__attachment--color--active: rgba(0, 0, 0, 0.3);
}

    :root.dark {
        --w-email__attachment--color: #2f2f2f;
        --w-email__attachment--text-color: f4f4f4;
        --w-email__attachment--color--hover: rgba(255, 255, 255, 0.1);
        --w-email__attachment--color--active: rgba(255, 255, 255, 0.2);
    }

.email-attachment {
    background-color: var(--w-email__attachment--color);
    color: var(--w-email__attachment--text-color);
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px;
    transition: ease-in 0.05s all;
    width: 140px;
}

    .email-attachment:hover {
        background-color: var(--w-email__attachment--color--hover);
        cursor: pointer;
    }

    .email-attachment:active {
        background-color: var(--w-email__attachment--color--active);
        cursor: pointer;
    }

.email-attachment__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.w-email__header {
    padding: 0 5px;
    display: flex;
    gap: 5px;
}

.w-email__heading--subject {
    margin: 0px 0px 4px 0px;
    font-size: 14px;
    padding: 8px 4px;
    font-weight: 600;
    border-radius: 3px;
}
