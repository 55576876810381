
.ie-screen-block-container {
    position: absolute;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ie-screen-block-overlay {
    width: 100%;
    height: 100%;
    -ms-opacity: 0.5;
    opacity: 0.5;
    -webkit-filter: opacity(50%);
    -moz-filter: alpha(opacity=50);
    -o-filter: alpha(opacity=50);
    filter: alpha(opacity=50);
    background-color: white;
    position: fixed;
    z-index: 10000;
}

.ie-screen-block-message-container {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    height: 0;
    text-align: center;
    z-index: 10001;
}

.ie-screen-block-message {
    display: inline-block;
    text-align: left;
    background-color: #333;
    color: #f5f5f5;
    padding: 20px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    font-size: 20px;
    font-weight: bold;
    -webkit-filter: opacity(100%);
    -moz-filter: alpha(opacity=100);
    -o-filter: alpha(opacity=100);
    filter: alpha(opacity=100);
}
